import fb from "../firebase"

import {useEffect, useRef, useState} from 'react';
import { Service } from '../types/Service';
import { TextLabelAnalysis } from '../types/Analysis';
import { useHistory } from "react-router-dom";

const getTextLabels = process.env.NODE_ENV === 'production' ?
    fb.app().functions('europe-west1').httpsCallable('getTextLabels') :
    fb.app().functions().httpsCallable('getTextLabels');

const usePostTextLabelService = (textId: string) => {
    const request = {textId};
    const [result, setResult] = useState<Service<TextLabelAnalysis>>({
        status: 'loading', request
    });
    let history = useHistory();
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        setResult({status: 'loading', request})
        getTextLabels({ textId: textId })
            .then((result) => {
                setResult({ status: 'loaded', payload: result.data, request })
                if (result.data.book && mountedRef.current) {
                    const book = result.data.book;
                    const new_path = '/t/' + encodeURI(book.title + (book.author ? ", " + book.author : ""));
                    history.replace(new_path);
                }
            })
            .catch((error) => {
                console.log('error', error);
                setResult({ status: 'error', error, request })
            });
        return () => {
            mountedRef.current = false;
        };
    }, [textId, history]);

    return result;
};

export default usePostTextLabelService;