import React, {ReactNode, useEffect, useState} from "react";
import fb from "./firebase";
import usePostExplainMatchService from "./services/usePostExplainMatchService";
import {ClickAwayListener, Fade, Paper, Popper, Snackbar} from "@material-ui/core";
import Alert from "./Alert";
import ExplanationPane from "./ExplanationPane";

export default function ExplainWrapper(props: { query: string, textId: number, children: ReactNode, key?: string | number }) {
    let user = fb.auth().currentUser;
    const [children, setChildren] = useState<ReactNode>();

    const [quota, setQuota] = useState(0);
    const [explanationAnchorEl, setExplanationAnchorEl] = useState<HTMLDivElement | null>();
    const [requestExplanation, setRequestExplanation] = useState(false);
    const [openExplanation, setOpenExplanation] = useState<boolean>(false);
    const [openExplanationWarning, setOpenExplanationWarning] = useState<boolean>(false);
    const [openExplanationQuotaWarning, setOpenExplanationQuotaWarning] = useState<boolean>(false);

    useEffect(() => {
        let gpt3QuotaRef = fb.database().ref('user-settings/' + (user && user.uid) + '/quota/gpt3');
        const handler = gpt3QuotaRef.on("value", (snapshot) => setQuota(snapshot.val()))
        return () => gpt3QuotaRef.off('value', handler);
    }, []);

    const handleCloseExplanationWarning = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenExplanationWarning(false);
    };
    const handleCloseExplanationQuotaWarning = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenExplanationQuotaWarning(false);
    };

    const matchExplainService = usePostExplainMatchService(
        props.textId,
        props.query,
        requestExplanation && quota > 0);


    const onExplain = (user: fb.User | null, quota: number) => {
        return user && !user.isAnonymous
        ? quota > 0
            ? () => {
                setRequestExplanation(true);
                setOpenExplanation(true);
            }
            : () => setOpenExplanationQuotaWarning(true)
        : () => setOpenExplanationWarning(true)
    };

    useEffect(() => {
        const childrenWithProps = React.Children.map(props.children, (child) => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {onExplain: onExplain(user, quota), key: props.key});
            }
            return child;
        });
        setChildren(childrenWithProps);
    }, [quota, props.key, props.textId]);

    return (
        <div ref={(ref) => setExplanationAnchorEl(ref)}>
            {openExplanationWarning &&
            <Snackbar open={openExplanationWarning} autoHideDuration={3000} onClose={handleCloseExplanationWarning}>
              <Alert onClose={handleCloseExplanationWarning} severity="warning">
                This feature is only available to logged in users.
              </Alert>
            </Snackbar>
            }
            {openExplanationQuotaWarning &&
            <Snackbar open={openExplanationQuotaWarning} autoHideDuration={3000}
                      onClose={handleCloseExplanationQuotaWarning}>
              <Alert onClose={handleCloseExplanationQuotaWarning} severity="warning">
                You don't have enough quota to use this feature.
              </Alert>
            </Snackbar>
            }
            {openExplanation &&
            <ClickAwayListener onClickAway={() => setOpenExplanation(false)}>
              <Popper open={openExplanation} anchorEl={explanationAnchorEl} placement={'top'} transition>
                  {({TransitionProps}) => (
                      <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                              <ExplanationPane matchExplainService={matchExplainService}/>
                          </Paper>
                      </Fade>
                  )}
              </Popper>
            </ClickAwayListener>
            }
            {children}
        </div>
    );
}
