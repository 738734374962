import fb from "../firebase"

import {useEffect, useRef, useState} from 'react';
import {Service} from '../types/Service';
import {AddLabelResult} from '../types/Analysis';
import {hashCode} from "../utils";

const getLabelTexts = process.env.NODE_ENV === 'production' ?
    fb.app().functions('europe-west1').httpsCallable('addLabel') :
    fb.app().functions().httpsCallable('addLabel');

const usePostAddLabelService = (label: string, callback?: (e?: Error | undefined) => void) => {
    const request = {label}
    const [result, setResult] = useState<Service<AddLabelResult>>({
        status: 'loading', request
    });

    label = label.trim();

    const mountedRef = useRef<boolean>(false);
    useEffect(() => {

        let currentUser = fb.auth().currentUser;

        setResult({status: 'loading', request})

        if (currentUser) {
            let myUserId = currentUser.uid;
            let labelKey = hashCode(label);
            let labelCountRef = fb.database().ref('user-labels/' + myUserId + '/count');

            fb.database().ref('user-labels/' + myUserId + '/list/' + labelKey)
                .set({label: label}, (error: Error | null) => {
                    if (error) {
                        console.log('error', error);
                        callback && callback(error);
                        setResult({status: 'error', error, request})
                    } else {
                        labelCountRef.transaction(function(currentRank) {
                            return (currentRank || 0) + 1;
                        });
                        // setResult({status: 'loaded', payload: {'label_id': labelKey + ''}});
                        getLabelTexts({label: label})
                            .then((result) => {
                                callback && callback();
                                setResult({status: 'loaded', payload: result.data, request});
                            })
                            .catch((error) => {
                                console.log('error', error);
                                callback && callback(error);
                                setResult({status: 'error', error, request})
                            });
                    }
                });
        } else {
            mountedRef.current = true;
            getLabelTexts({label: label})
                .then((result) => {
                    if (!mountedRef.current) return;
                    callback && callback();
                    setResult({status: 'loaded', payload: result.data, request});
                })
                .catch((error) => {
                    console.log('error', error);
                    if (!mountedRef.current) return;
                    callback && callback(error);
                    setResult({status: 'error', error, request})
                });
        }
        return () => {
            mountedRef.current = false;
        };
    }, [label]);

    return result;
};

export default usePostAddLabelService;