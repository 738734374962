import {useHistory, useLocation} from "react-router-dom";
import usePostSearchService from "./services/usePostSearchService";
import usePostSearchLabelsService from "./services/usePostSearchLabelsService";
import BookMatchItem from "./BookMatchItem";
import LabelMatchItem from "./LabelMatchItem";
import React, {useEffect, useState} from "react";
import Histogram from "./Histogram";
import SearchContainer from "./SearchContainer";
import {LinearProgress, Tooltip} from "@material-ui/core";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import {ReactSortable} from "react-sortablejs";
import {Book, BookMatch, LabelMatch} from "./types/Analysis";
import AddLabelModal from "./AddLabelModal";
import {compareProperty, useLocalStorage} from "./utils";
import {isMobile} from "react-device-detect";
import Hashes from "jshashes";
import fb from "./firebase";
import BookItem from "./BookItem";

export interface ItemType {
    id: number;
    labelMatch: LabelMatch;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchPage() {
    let query = useQuery();
    const q = query.get("q") || "";
    document.title = q ? q + ' | Text.Study' : "Search Texts by Narrative | Text.Study";


    const [timeRange, setTimeRange] = useState<{ from?: number, to?: number }>({from: undefined, to: undefined});
    const [liveQuery, setLiveQuery] = useState<string>(q);
    const [debouncedQuery, setDebouncedQuery] = useState<string>(q);
    const [showAddLabel, setShowAddLabel] = useState<boolean>(false);

    const [anonHash, setAnonHash] = useState<string>();

    const [aboutSearchVisible, setAboutSearchVisible] = useState<boolean>(false);

    const [activeLabelsVisible, setActiveLabelsVisible] = useState<boolean>(true);
    const [possibleLabelsVisible, setPossibleLabelsVisible] = useState<boolean>(true);

    const [searchLabelsActive, setSearchLabelsActive] = useState<ItemType[]>([]);
    const [searchLabelsReserve, setSearchLabelsReserve] = useState<ItemType[]>([]);

    const [pinnedLabels, setPinnedLabels] = useLocalStorage("pinnedLabels", []);


    const labelService = usePostSearchLabelsService(debouncedQuery);

    const textService = usePostSearchService(q,
        timeRange && timeRange.from, timeRange && timeRange.to,
        searchLabelsActive.map((value: ItemType) => value.labelMatch.label_id),
        searchLabelsActive.length > 0 &&
        (labelService.status === 'loaded' || labelService.status === 'updating'));


    let history = useHistory();

    useEffect(() => {
        const currentUser = fb.auth().currentUser;
        const myUserId = currentUser && currentUser.uid;
        const anonHash = !myUserId ? ('anon-' + new Hashes.MD5().hex(JSON.stringify(Math.random())).substring(0, 10)) : undefined;
        setAnonHash(anonHash);
    }, []);

    useEffect(() => {
        if (labelService.status === 'loaded') {

            setSearchLabelsActive(labelService.payload.labels
                .filter((labelMatch) =>
                    labelMatch.score > 0.5 ||
                    labelMatch.label.toLowerCase() === debouncedQuery.trim().toLowerCase()
                )
                .map((labelMatch) => {
                    return {
                        id: labelMatch.label_id, labelMatch: {
                            ...labelMatch,
                            score: labelMatch.label.toLowerCase() === debouncedQuery.trim().toLowerCase() ? 1.01 : labelMatch.score
                        }
                    }
                })
                .sort((a, b) => compareProperty(b.labelMatch.score, a.labelMatch.score))
            );
            setSearchLabelsReserve(labelService.payload.labels
                .filter((labelMatch) => labelMatch.score <= 0.5 && labelMatch.label.toLowerCase() !== debouncedQuery.trim().toLowerCase())
                .map((labelMatch) => {
                    return {id: labelMatch.label_id, labelMatch: labelMatch}
                })
            );
        }
    }, [labelService.status]);


    const topClass = debouncedQuery.length === 0 ? "content-center sm:pt-20" : "content-center";
    const labels = (labelService.status === 'loaded' && labelService.payload.labels) ||
        (labelService.status === 'updating' && labelService.prevPayload.labels);

    const directMatches0 = textService.status === 'loaded'
        ? textService.payload.fts_matches
        : textService.status === 'updating'
            ? textService.prevPayload.fts_matches
            : [];

    const directMatches = directMatches0 ? directMatches0.map((book: Book) => <BookItem book={book}/>) : [];

    const searchResults0 = textService.status === 'loaded'
        ? textService.payload.books
        : textService.status === 'updating'
            ? textService.prevPayload.books
            : [];
    const searchResults = searchResults0.sort((a: BookMatch, b: BookMatch) =>
        compareProperty(Math.min(...b.matches || [0]), Math.min(...a.matches || [0])) ||
        compareProperty(
            b.matches && b.matches.reduce((k, l) => k + l, 0),
            a.matches && a.matches.reduce((k, l) => k + l, 0)
        )
    );

    let data = searchResults &&
        searchResults
            .filter(bookMatch => typeof bookMatch.pubyear !== 'undefined' && bookMatch.pubyear > 0)
            .reduce(function (acc: any, obj) {
                let key: number = obj["pubyear"] || 0;
                if (!key) return acc;
                if (!acc[key]) acc[key] = 0;
                acc[key] += 1;
                return acc;
            }, {});

    data = Object.entries(data).map(entry => ({x: -(-entry[0]), y: entry[1]}));

    const bookMatches = searchResults &&
        searchResults
            .filter(bookMatch => typeof timeRange.from === 'undefined' || typeof timeRange.to === 'undefined' ||
                (bookMatch.pubyear && bookMatch.pubyear > timeRange.from && bookMatch.pubyear < timeRange.to))
            .map(bookMatch => {
                const labelIds = textService.status !== 'init' && textService.request.labelIds;
                const searchHash = new Hashes.MD5().hex(JSON.stringify(labelIds.sort()) + bookMatch.book_id).substr(0, 6);
                return <BookMatchItem bookMatch={bookMatch} labels={searchLabelsActive}
                                      key={searchHash} disableTooltips={isMobile}
                                      anonHash={anonHash}/>
            });


    return (
        <div className={topClass}>
            <div className={"px-4 md:flex mt-4 flex-col justify-center content-center align-middle bg-white rounded-t"}>
                <div className={"sm:text-center pt-4 sm:mt-0 mb-4 sm:mb-2"}>
                    <span className={'text-xl'}>Search by narrative elements</span>
                </div>
                <div className={'w-full flex flex-col sm:flex-row'}>
                    <div
                        className={'my-4 sm:my-0 sm:w-1/8 md:w-1/6 sm:flex-shrink-0 sm:pt-5 sm:pr-4 sm:text-right text-center text-2xl'}>
                        {debouncedQuery.length > 0 && <span>1.</span>}
                    </div>
                    <SearchContainer
                        className={'w-full sm:ml-4 sm:w-3/4 sm:my-4 mx-auto flex-1'}
                        query={q}
                        onQueryChanged={query => setLiveQuery(query)}
                        onDebouncedQueryChanged={debouncedQuery => {
                            setDebouncedQuery(debouncedQuery);
                            const new_path = '/search' + (debouncedQuery ? ('?q=' + debouncedQuery) : '');
                            history.replace(new_path);
                        }}
                        disabled={searchLabelsActive.length === 0 || labelService.status !== 'loaded' || liveQuery !== debouncedQuery}
                    />
                    <div className={'hidden sm:block sm:w-1/8 md:w-1/6 flex-shrink-0'}>

                    </div>
                </div>

                {q.length === 0 && debouncedQuery.length === 0 &&
                <div className={"pt-6 max-w-md mx-auto mt-4 sm:mt-0 text-gray-600"}>
                  <h1 className={'text-lg mb-5 text-center cursor-pointer'}
                      onClick={() => setAboutSearchVisible(!aboutSearchVisible)}>
                    How the search works
                      {aboutSearchVisible &&
                      <KeyboardArrowDownIcon/>
                      }
                      {!aboutSearchVisible &&
                      <KeyboardArrowRightIcon/>
                      }
                  </h1>
                    {aboutSearchVisible && <div>
                      <div>
                        The search engine has a list of short descriptions of narrative elements, or <i>labels</i>.
                        You can add labels that are relevant to your interests.
                        After a label is added, every text in the database is evaluated for its relevance to it.
                        Depending on the size of the database, the number of labels to evaluate, and the
                        available compute capacity, this process can take many days.
                      </div>
                      <div className={'my-4'}>
                        When the search engine receives a query, it suggests existing labels that are deemed relevant to
                        it.
                        A set of most relevant labels that define the search request is automatically proposed.
                        One can manually modify this set by removing irrelevant labels or adding other relevant labels
                        to it.
                        These labels and their precomputed relevance values for the texts in the database are then used
                        for finding the most relevant texts in the database.
                      </div>
                    </div>}
                </div>
                }

                <div className={'w-full flex flex-col sm:flex-row bg-white'}>
                    {(labelService.status === 'loading' ||
                        labelService.status === 'updating' ||
                        labelService.status === 'loaded') &&
                    <div
                        className={'mt-8 mb-2 sm:my-0 sm:w-1/8 md:w-1/6 sm:flex-shrink-0 sm:pt-2 sm:pr-4 sm:text-right text-center text-2xl'}>
                        {labelService.status !== 'loading' && debouncedQuery.length > 0 && <span>2.</span>}
                    </div>
                    }
                    <div className={'w-full'}>
                        {(labelService.status === 'loading' ||
                            labelService.status === 'updating' ||
                            labelService.status === 'loaded') && (
                            <div className={
                                (labelService.status === 'loaded' || q.length + debouncedQuery.length === 0) && 'invisible h-1' ||
                                'mx-auto flex flex-row w-full justify-center place-items-center h-1'}>
                                <LinearProgress className={'w-full ml-2 sm:ml-4'}/>
                            </div>
                        )}

                        {labels && (labelService.status === 'loaded' || labelService.status === 'updating') && debouncedQuery.length > 0 &&
                        <div className={'mx-auto text-sm italic px-4 my-2 mb-0 cursor-pointer'}
                             onClick={() => setActiveLabelsVisible(!activeLabelsVisible)}
                        >
                          Following labels are <b>used for search</b>.
                          You can remove irrelevant labels.

                            {activeLabelsVisible &&
                            <KeyboardArrowDownIcon className={'cursor-pointer'}/>
                            }
                            {!activeLabelsVisible &&
                            <KeyboardArrowRightIcon className={'cursor-pointer'}/>
                            }
                        </div>
                        }

                        <div className={'mx-auto'}>
                            {
                                (labelService.status === 'loaded' || labelService.status === 'updating')
                                && debouncedQuery.length > 0 && activeLabelsVisible
                                && <div>

                                  <ReactSortable
                                      className={'py-1'}
                                      group={{name: 'default', put: ['default'], pull: true}}
                                      handle={isMobile && 'sortablehandle' || undefined}
                                      list={searchLabelsActive} setList={setSearchLabelsActive}>
                                      {searchLabelsActive.map((labelMatch, idx) => (
                                          <div
                                              key={debouncedQuery + labelMatch.id}
                                              className={'cursor-move group flex flex-row justify-between justify-center w-full bg-gray-50 my-1'}>

                                              <LabelMatchItem labelMatch={labelMatch.labelMatch}
                                                              underlineLink={true}
                                                              className={'bg-gray-50 py-0.5 flex flex-1 group justify-content-start px-4'}/>
                                              <Tooltip title="Remove from search criteria">
                                                  <CloseIcon
                                                      className={'text-gray-400 invisible group-hover:visible cursor-pointer'}
                                                      onClick={() => {
                                                          const popped = searchLabelsActive.splice(idx, 1);
                                                          setSearchLabelsReserve([popped[0], ...searchLabelsReserve]);
                                                          setSearchLabelsActive(searchLabelsActive);
                                                      }}
                                                  />
                                              </Tooltip>
                                              <div className={isMobile ? 'hidden' : ''}>
                                                  <DragIndicatorIcon className={'text-gray-400'}/>
                                              </div>
                                          </div>
                                      ))}
                                  </ReactSortable>

                                </div>
                            }
                            {(labelService.status === 'loaded' || labelService.status === 'updating')
                            && debouncedQuery.length > 0 &&
                            <div className={'text-sm italic px-4 pt-2 inline-block cursor-pointer'}
                                 onClick={() => setPossibleLabelsVisible(!possibleLabelsVisible)}
                            >
                              Following labels are <b>not used</b> for search.
                              You can activate labels from this list.

                                {possibleLabelsVisible &&
                                <KeyboardArrowDownIcon className={'cursor-pointer'}/>
                                }
                                {!possibleLabelsVisible &&
                                <KeyboardArrowRightIcon className={'cursor-pointer'}/>
                                }
                            </div>
                            }
                            <div>
                                {
                                    (labelService.status === 'loaded' || labelService.status === 'updating')
                                    && debouncedQuery.length > 0 && possibleLabelsVisible
                                    && <ReactSortable
                                        className={'py-2'}
                                        group={{name: 'default', put: ['default'], pull: true}}
                                        handle={isMobile && 'sortablehandle' || undefined}
                                        list={searchLabelsReserve} setList={setSearchLabelsReserve}>
                                        {searchLabelsReserve.slice(0, 10).map((labelMatch, idx) => (
                                            <div
                                                key={debouncedQuery + labelMatch.id}
                                                className={'cursor-move group flex flex-row justify-between justify-center w-full bg-gray-50 my-1'}>
                                                <LabelMatchItem labelMatch={labelMatch.labelMatch}
                                                                underlineLink={true}
                                                                className={'bg-gray-50 py-0.5 flex flex-1 group justify-content-start px-4'}
                                                />
                                                <Tooltip title="Add to search criteria">
                                                    <AddIcon
                                                        className={'text-gray-400 invisible group-hover:visible cursor-pointer'}
                                                        onClick={() => {
                                                            const popped = searchLabelsReserve.splice(idx, 1);
                                                            setSearchLabelsActive([...searchLabelsActive, popped[0]]);
                                                            setSearchLabelsReserve(searchLabelsReserve);
                                                        }}
                                                    />
                                                </Tooltip>
                                                <div className={isMobile ? 'hidden' : ''}>
                                                    <DragIndicatorIcon className={'text-gray-400'}/>
                                                </div>
                                            </div>
                                        ))}
                                    </ReactSortable>
                                }
                                {
                                    (labelService.status === 'loaded' || labelService.status === 'updating')
                                    && debouncedQuery.length > 0
                                    &&
                                    <div
                                        className={'text-sm italic pl-4 my-4 mt-1 flex flex-row items-center justify-between'}>
                                      <div>
                                        Do not see any relevant labels? Consider
                                        <span className={'mx-1 cursor-pointer underline whitespace-nowrap'}
                                              onClick={() => setShowAddLabel(!showAddLabel)}>
                                    adding a new label.
                                </span>
                                      </div>
                                      <AddCircleOutlineIcon className={'justify-self-end cursor-pointer text-gray-500'}
                                                            onClick={() => setShowAddLabel(!showAddLabel)}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'hidden sm:block sm:w-1/8 md:w-1/6 flex-shrink-0'}>

                    </div>
                </div>
                <AddLabelModal open={showAddLabel} setOpen={setShowAddLabel}/>
            </div>
            <div className={'sm:px-4 w-full flex flex-col sm:flex-row bg-white'}>
                {debouncedQuery.length > 0 && (labelService.status === 'loaded' || labelService.status === 'updating') &&
                (textService.status === 'loaded' || textService.status === 'updating') &&
                <div
                    className={'my-4 sm:my-0 sm:w-1/8 md:w-1/6 sm:flex-shrink-0 sm:pt-7 sm:pr-4 sm:text-right text-center text-2xl'}>
                  3.
                </div>
                }
                <div className={"content-center mx-auto"}>

                    {labelService.status === 'loaded' && (textService.status === 'loading' || textService.status === 'updating') &&
                    <LinearProgress/>
                    }
                    {debouncedQuery.length > 0 && (labelService.status === 'loaded' || labelService.status === 'updating') &&
                    (textService.status === 'loaded' || textService.status === 'updating') && data.length > 0 &&
                    <Histogram data={data} lastDrawLocation={timeRange} setLastDrawLocation={setTimeRange}/>
                    }
                    {debouncedQuery.length > 0 && (labelService.status === 'loaded' || labelService.status === 'updating') &&
                    (textService.status === 'loaded' || textService.status === 'updating') && (
                        <div className={"mx-auto md:flex md:flex-col md:justify-center"}>

                            {directMatches.length > 0 &&
                            <div className={"w-full md:order-1 mb-4"}>
                              <div className={'inline-block'}>
                                    <span className={"font-bold"}>
                                        {directMatches && directMatches.length >= 10
                                            ? "Top-10 matches based on the author or title"
                                            : "Matches based on the author or title"}
                                    </span>
                              </div>
                                {directMatches}
                            </div>
                            }

                            <div className={"w-full md:order-1"}>
                                <div className={'inline-block'}>
                                    <span className={"font-bold"}>
                                        {bookMatches && bookMatches.length >= 100
                                            ? "Top-100 matching texts"
                                            : "Matching texts"}
                                    </span>
                                    <br className={'sm:hidden'}/>
                                    <span className={"ml-1 text-sm"}>
                                    (based on the selected labels)
                                    </span>
                                </div>
                                {bookMatches}
                            </div>
                        </div>
                    )}
                    {textService.status === 'error' && (
                        <div>Backend error. {JSON.stringify(textService.error)}</div>
                    )}

                </div>
                <div className={'hidden sm:block sm:w-1/8 md:w-1/6 sm:flex-shrink-0'}>

                </div>
            </div>
        </div>
    );
}

export default SearchPage;