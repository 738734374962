import React, {useEffect, useState} from "react";
import fb, {uiConfig} from "./firebase";
import {StyledFirebaseAuth} from "react-firebaseui";
import firebase from "firebase";
import {useHistory, useLocation} from "react-router-dom";
import {useLocalStorage} from "./utils";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

type SignInProps = {
    isSignedIn: boolean
    user: firebase.User | null
    onAuthStateChanged: (user: firebase.User | null) => void
}

function SignInScreen(props: SignInProps) {
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);

    let history = useHistory();
    let query = useQuery();
    const to = query.get("to") || "";
    const toUrl = to === '' ? '/' : decodeURIComponent(to);
    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = fb.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            props.onAuthStateChanged(user);

            if (user && !user.isAnonymous) {
                if (!user.emailVerified && !emailVerificationSent) {
                    console.log('Email is not verified');
                    user.sendEmailVerification();
                    setEmailVerificationSent(true);
                }
                if (user.metadata.creationTime && Date.now() - Date.parse(user.metadata.creationTime) < 10000) {
                    history.replace('/account');
                } else {
                    history.replace(toUrl);
                }
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [toUrl, emailVerificationSent]);

    if (!props.isSignedIn || !isSignedIn) {
        return (
            <div className={"mt-14 sm:mt-20 clear-all flex flex-col items-center justify-center"}>
                <StyledFirebaseAuth className="mx-auto" uiConfig={uiConfig} firebaseAuth={fb.auth()}/>
                <div className={"mt-10 px:8 max-w-sm text-lg text-gray-500"}>
                    When signed in, you can manage your labels
                    used for in-depth analysis of the texts, have your requests processed faster,
                    and use advanced language-understanding features.
                </div>
            </div>
        );
    }

    return null;
}

export default SignInScreen;