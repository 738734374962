import fb from "../firebase"

import {useEffect, useRef, useState} from 'react';
import { Service } from '../types/Service';
import { LabelTextAnalysis } from '../types/Analysis';
import { useHistory } from "react-router-dom";

const getLabelTexts = process.env.NODE_ENV === 'production' ?
    fb.app().functions('europe-west1').httpsCallable('getLabelTexts') :
    fb.app().functions().httpsCallable('getLabelTexts');

const usePostLabelTextService = (labelId: string, from_year?: number, to_year?: number) => {
    const request = {labelId, from_year, to_year};
    const [result, setResult] = useState<Service<LabelTextAnalysis>>({
        status: 'loading', request
    });
    let history = useHistory();
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        mountedRef.current = true;
        if (result.status === 'loaded') {
            setResult({status: 'updating', prevPayload: result.payload, request})
        } else {
            setResult({status: 'loading', request})
        }
        getLabelTexts({ labelId: labelId, from_year, to_year })
            .then((result) => {
                setResult({ status: 'loaded', payload: result.data, request })
                if (result.data.label && mountedRef.current) {
                    const label = result.data.label;
                    const new_path = '/l/' + encodeURI(label.label);
                    history.replace(new_path);
                }
            })
            .catch((error) => {
                console.log('error', error);
                setResult({ status: 'error', error, request })
            });
        return () => {
            mountedRef.current = false;
        };
    }, [labelId, from_year, to_year, history]);

    return result;
};

export default usePostLabelTextService;