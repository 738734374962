import SlackFeedback from 'react-slack-feedback'
import './App.css';
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";

import "firebase/auth";
import {useLocalStorage} from "./utils";
import TextPage from "./TextPage";
import firebase from "firebase";
import LabelPage from "./LabelPage";
import SearchPage from "./SearchPage"
import Page from "./Page";
import Imprint from "./Imprint";
import AuthorPage from "./AuthorPage";
import SignInScreen from "./SignInScreen";
import Home from "./Home";
import ScrollToTop from "./ScrollToTop";
import Header from "./Header";
import {AccountPage} from "./AccountPage";
import {UserLabels} from "./UserLabels";
import fb from "./firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import Privacy from "./Privacy";
import AnalyticsWrapper from "./AnalyticsWrapper";


function App() {
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);

    const submitFeedback = process.env.NODE_ENV === 'production' ?
        fb.app().functions('europe-west1').httpsCallable('submitFeedback') :
        fb.app().functions().httpsCallable('submitFeedback');

    const [user, loading, error] = useAuthState(fb.auth());
    if (user) {
        let onlineStateRef = fb.database().ref('users-online/' + user.uid);
        onlineStateRef.set(true);
        onlineStateRef.onDisconnect().set(false);
    }

    function onFocus() {
        if (user) {
            let onlineStateRef = fb.database().ref('users-online/' + user.uid);
            onlineStateRef.set(true);
            onlineStateRef.onDisconnect().set(false);
        }
    }

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        return () => {
            window.removeEventListener('focus', onFocus);
        };
    }, [user]);

    return (
        <Router>
            <ScrollToTop/>
            <AnalyticsWrapper/>
            <div className="container 2xl:max-w-6xl mx-auto px-4 flex flex-col h-screen justify-between">
                <div>
                    <Switch>
                        <Route path="/search">
                            <Page>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <SearchPage/>
                                </div>
                            </Page>
                        </Route>
                        <Route path="/t/:textIdentifier+">
                            <Page>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <TextPage/>
                                </div>
                            </Page>
                        </Route>
                        <Route path="/l/:labelIdentifier+">
                            <Page>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <LabelPage/>
                                </div>
                            </Page>
                        </Route>
                        <Route path="/author/:authorIdentifier+">
                            <Page>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <AuthorPage/>
                                </div>
                            </Page>
                        </Route>
                        <Route path="/contact">
                            <Page title={"Contact"}>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <Imprint/>
                                </div>
                            </Page>
                        </Route>
                        <Route path="/privacy">
                            <Page title={"Contact"}>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <Privacy/>
                                </div>
                            </Page>
                        </Route>
                        <Route path={"/signin"}>
                            <Page title={"Text.Study Sign-in"}>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <SignInScreen isSignedIn={isSignedIn}
                                                  user={user || null}
                                                  onAuthStateChanged={(user: firebase.User | null) => {
                                                      if (user) {
                                                          setIsSignedIn(true);
                                                          let onlineStateRef = fb.database().ref('users-online/' + user.uid);
                                                          onlineStateRef.set(true);
                                                          console.log('set user online');
                                                          onlineStateRef.onDisconnect().set(false);
                                                      }
                                                  }}/>
                                </div>
                            </Page>
                        </Route>

                        <Route path="/account/labels">
                            <Page title={"Text.Study: My labels"}>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <UserLabels/>
                                </div>
                            </Page>
                        </Route>

                        <Route path="/account">
                            <Page title={"Text.Study: Account"}>
                                <div className={"flex flex-col"}>
                                    <Header/>
                                    <AccountPage/>
                                </div>
                            </Page>
                        </Route>

                        <Route path="/">
                            <Page title={"Text.Study: Search by Narrative Elements"}>
                                <Header/>
                                <Home/>
                            </Page>
                        </Route>
                    </Switch>
                </div>

                <div className="h-10 py-20 pb-24 mx-auto">
                    <Link to="/" className={"mx-4"}>Home</Link>
                    |
                    <a href="https://twitter.com/TextDotStudy" target={'_blank'} className={"mx-4"}>Twitter</a>
                    |
                    <Link to="/contact" className={"mx-4"}>Contact</Link>
                    |
                    <Link to="/privacy" className={"mx-4"}>Privacy</Link>
                    <div className={'hidden sm:block'}>
                        <SlackFeedback
                            defaultSelectedType={'improvement'}
                            translations={{"header.title": "How can we improve?"}}
                            showChannel={false}
                            showIcon={false}
                            icon={() => null}
                            onSubmit={(payload: any, success: () => {}, error: () => {}) => {
                                submitFeedback({payload: payload})
                                    .then((result) => {
                                        success();
                                    })
                                    .catch((e) => {
                                        console.log('error', error);
                                        error();
                                    });
                            }}
                            theme={{
                                trigger: {
                                    padding: '6px 0.75em',
                                    borderRadius: '6px',
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
