import Obfuscate from 'react-obfuscate';

function Imprint() {
    return (

            <div className="bg-white shadow overflow-hidden sm:rounded-lg max-w-md mx-auto mt-20">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Contact</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Website owner</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Dr. Carmen Reichert</dd>
                        </div>
                        <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Email address</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Obfuscate
                                    email="hello@text.study"
                                />
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
    )
}

export default Imprint;