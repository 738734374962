import {useParams} from "react-router-dom";
import usePostLabelTextService from "./services/usePostLabelTextsService";
import BookMatchItem from "./BookMatchItem";
import AddLabel from "./AddLabel";
import React, {useEffect, useState} from "react";
import {LinearProgress} from "@material-ui/core";
import LabelPlots from "./LabelPlots";
import ExplainWrapper from "./ExplainWrapper";
import fb from "./firebase";
import Hashes from "jshashes";
import numeral from "numeral";


function LabelPage() {
    const {labelIdentifier} = useParams<{ labelIdentifier: string }>();

    document.title = labelIdentifier + ' - Text.Study' || "Text.Study: Search by Narrative Elements";
    const [done, setDone] = useState<number>(100);
    const [totalWordCount, setTotalWordCount] = useState<number>();
    const [timeRange, setTimeRange] = useState<{ from?: number, to?: number }>({from: undefined, to: undefined});

    useEffect(() => {
        const md5Hash = new Hashes.MD5().hex(labelIdentifier).substr(0, 12);
        let refDone = fb.database().ref('label-stats/' + md5Hash + '/done');
        const handlerDone = refDone.on('value', (snapshot: fb.database.DataSnapshot) => {
            setDone(snapshot.val());
        });
        let refTotalWordCount = fb.database().ref('label-stats/' + md5Hash + '/total_word_count');
        const handlerTotalWordCount = refTotalWordCount.on('value', (snapshot: fb.database.DataSnapshot) => {
            setTotalWordCount(snapshot.val());
        });
        return () => {
            refDone.off('value', handlerDone);
            refTotalWordCount.off('value', handlerTotalWordCount);
        }
    }, [labelIdentifier]);

    const service = usePostLabelTextService(labelIdentifier,
        timeRange.from && Math.round(timeRange.from), timeRange.to && Math.round(timeRange.to));


    const bookMatches = service.status === 'loaded' &&
        service.payload.books
            .filter(bookMatch => typeof timeRange.from === 'undefined' || typeof timeRange.to === 'undefined' ||
                (bookMatch.pubyear && bookMatch.pubyear > timeRange.from && bookMatch.pubyear < timeRange.to))
            .map(bookMatch =>
                <ExplainWrapper query={labelIdentifier} textId={bookMatch.book_id} key={bookMatch.book_id}>
                    <BookMatchItem bookMatch={bookMatch} />
                </ExplainWrapper>
            );

    let data = service.status === 'loaded' ?
        service.payload.books
            .filter(bookMatch => typeof bookMatch.pubyear !== 'undefined' && bookMatch.pubyear > 0)
            .filter(bookMatch => typeof timeRange.from === 'undefined' || typeof timeRange.to === 'undefined' ||
                (bookMatch.pubyear && bookMatch.pubyear > timeRange.from && bookMatch.pubyear < timeRange.to))
            .reduce(function (acc: any, obj) {
                let key: number = obj["pubyear"] || 0;
                if (!key) return acc;
                if (!acc[key]) acc[key] = 0;
                acc[key] += 1;
                return acc;
            }, {})
        : {}
    data = Object.entries(data).map(entry => ({x: -(-entry[0]), y: entry[1]}))

    const wc = totalWordCount && totalWordCount < 1000
        ? numeral(totalWordCount).format('0a')
        : numeral(totalWordCount).format('0.0a')
    const hint = wc + ' words already processed';
    return (
        <div className={'sm:w-3/4 sm:my-4 mx-auto bg-white sm:px-4 pt-2 sm:rounded'}>

            <div className={"text-center"}>
                <div className={'flex items-end'}>
                    {done < 0.99 &&
                    <div className={'flex-none text-sm text-gray-400'} title={hint}>
                        {Math.round(done * 100 * 100) / 100}%
                    </div>
                    }

                    <div className={'flex-grow px-2'}>The label <b>{labelIdentifier}</b></div>

                    {done < 0.99 && <div className={'flex-none invisible text-sm text-gray-400'} title={hint}>
                        {Math.round(done * 100 * 100) / 100}%
                    </div>}
                </div>

                {service.status === 'loading' &&
                <div className={"my-4"}>
                  Loading...
                  <LinearProgress/>
                </div>}

            </div>

            {((service.status === 'loaded' && data.length > 0) || service.status === 'updating') &&
            <div className={"mt-4"}>
                <LabelPlots timeRange={timeRange} setTimeRange={setTimeRange}
                            data={data}
                            matchCount={bookMatches && bookMatches.length || -1}
                            service={service}
                            labelIdentifier={labelIdentifier}/>
            </div>
            }

            {service.status === 'updating' && <LinearProgress/>}

            {service.status === 'loaded' && bookMatches}

            {service.status === 'error' && (
                <div>Backend error. {JSON.stringify(service.error)}</div>
            )}

            {service.status !== 'loading' && service.status !== 'updating' &&
            <div className={"mx-auto md:flex md:justify-center mt-4"}>
              <AddLabel/>
            </div>
            }
        </div>
    )
}

export default LabelPage;