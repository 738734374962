import React, {Dispatch, SetStateAction} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LabelPercentiles from "./LabelPercentiles";
import Histogram from "./Histogram";
import HistoricalUse from "./HistoricalUse";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,

        backgroundColor: theme.palette.background.paper,
    },
}));

export default function LabelPlots(props: {
    matchCount?: number;
    labelIdentifier: string,
    data: any,
    service: { status: string },
    timeRange: { from?: number, to?: number },
    setTimeRange: Dispatch<SetStateAction<{ from?: number | undefined; to?: number | undefined; }>>
}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    centered={true}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                >
                    <Tab label="Top matches" {...a11yProps(0)} />
                    <Tab label="Historical use" {...a11yProps(1)} />
                    <Tab label="Quantiles" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={'w-full'}>
                <div className={"text-center"}>
                    {props.matchCount && props.matchCount >= 100
                        ? "100 best matches for this time period"
                        : props.matchCount && props.matchCount > 0
                            ? props.matchCount + " texts within this time period"
                            : props.matchCount == 0 ? "No texts for this period" : ""
                    }
                </div>
                <Histogram data={props.data} updating={props.service.status === 'updating'}
                           lastDrawLocation={props.timeRange} setLastDrawLocation={props.setTimeRange}/>
            </TabPanel>
            <TabPanel value={value} index={1} className={'w-full'}>
                <div className={"text-center"}>Distribution of the top 5% most relevant texts over the decades</div>
                <HistoricalUse label={props.labelIdentifier}/>
            </TabPanel>
            <TabPanel value={value} index={2} className={'w-full'}>
                <div className={'flex flex-row w-full items-center justify-center'}>
                    <div className={'text-sm sm:text-base w-2/3 sm:w-auto pt-2'}>
                        This quantile plot shows how specific the label is and how well the system can use it to
                        separate relevant texts from irrelevant ones.
                        Less area under the curve means more precise analysis.
                    </div>
                    <div className={'w-1/3 sm:max-w-sm'}>
                        <LabelPercentiles label={props.labelIdentifier} size={150}/>
                    </div>
                </div>
            </TabPanel>
        </div>
    );
}

