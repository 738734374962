import {Link, useParams} from "react-router-dom";
import usePostTextLabelService from "./services/usePostTextLabelsService";
import LabelMatchItem from "./LabelMatchItem";
import AddLabel from "./AddLabel";
import React from "react";
import {LinearProgress, Tooltip} from "@material-ui/core";
import ExplainWrapper from "./ExplainWrapper";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {decodeHtml} from "./utils";
import Wrapper from "./lib/react-wiki-preview/Wrapper";
import {wikiLogo} from "./lib/react-wiki-preview/Content";

function TextPage() {
    const {textIdentifier} = useParams<{ textIdentifier: string }>();

    const service = usePostTextLabelService(textIdentifier);

    document.title = textIdentifier + ' - Text.Study' || "Text.Study: Search by Narrative Elements";

    const author = service.status === 'loaded' && service.payload.book.author && (
        <div>
            <span className={"text-sm leading-6"}>, by</span>
            <Link to={`/author/${service.payload.book.author}`} className={'text-gray-400 font-bold pl-1'}>
                {service.payload.book.author}
            </Link>
        </div>
    );
    const bookTitleAndAuthor = service.status === 'loaded' && service.payload.book &&
        <div className={"flex pl-1"}>
            {decodeHtml(service.payload.book.title)}
            {author}
        </div>
    return (
        <div className={'sm:w-3/4 sm:my-4 mx-auto bg-white sm:px-4 pt-2 sm:rounded'}>
            <div className={"text-center"}>
                <div className={"inline-block sm:flex justify-center"}>
                    <span>Labels matching </span>
                    {bookTitleAndAuthor
                        ? <span className={"pl-0.5 italic"}>{bookTitleAndAuthor}</span>
                        : <span className={"pl-0.5 italic"}>{textIdentifier}</span>
                    }
                    <div className={'flex flex-row justify-center'}>
                        <Tooltip title={'Top 100 labels for which this text is within top 3% of all matches'}>
                            <HelpOutlineIcon fontSize={'small'} className={'pl-2'}/>
                        </Tooltip>
                        {service.status === 'loaded' && service.payload.book.wikipedia_title &&
                        <div className={'pl-2'}>
                          <Wrapper keyword={service.payload.book.wikipedia_title}>
                              {wikiLogo('light')}
                          </Wrapper>
                        </div>
                        }
                    </div>
                </div>
                {service.status === 'loading' &&
                <div className={"my-4"}>
                  Loading...
                  <LinearProgress/>
                </div>
                }
            </div>
            {service.status === 'loaded' &&
            service.payload.labels.map(labelMatch => (
                <ExplainWrapper query={labelMatch.label} textId={service.payload.book.book_id}
                                key={labelMatch.label_id}>
                    <LabelMatchItem labelMatch={labelMatch}/>
                </ExplainWrapper>
            ))}
            {service.status === 'loaded' && service.payload.labels.length < 1 &&
            <div className={'text-center my-4 mb-6'}>
              No matching labels found. Probably, the text hasn't been analyzed yet.
            </div>
            }
            {service.status === 'error' && (
                <div>Backend error. {JSON.stringify(service.error)}</div>
            )}

            {service.status !== 'loading' && service.status !== 'updating' &&
            <div className={"mx-auto md:flex md:justify-center"}>
              <AddLabel/>
            </div>
            }
        </div>
    )
}

export default TextPage;