import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationIcon} from '@heroicons/react/outline'
import ScheduleIcon from '@material-ui/icons/Schedule';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import fb from "./firebase";
import {Link} from "react-router-dom";
import usePostAddLabelService from "./services/usePostAddLabelService";
import {CircularProgress} from "@material-ui/core";
import {isMobile} from "react-device-detect";
import {useWindowSize} from "./utils";

function AddLabelResult(props: { label: string, callback?: (e?: Error | undefined) => void }) {
    const service = usePostAddLabelService(props.label, props.callback);
    return (
        <div>
            {service.status === 'loading' && <CircularProgress/>}
            {service.status === 'loaded' && <div>Added</div>}
            {service.status === 'error' && <div>Error</div>}
        </div>
    )
}

export default function AddLabelModal(props: {
    open: boolean, setOpen: (v: boolean) => void,
    onAddLabel?: (label: string) => void
}) {

    const [label, setLabel] = useState("");
    const [submittedLabel, setSubmittedLabel] = useState<string | undefined>();

    const cancelButtonRef = useRef(null)

    let currentUser = fb.auth().currentUser;

    const inputRef = useRef<HTMLInputElement>(null);

    const size = useWindowSize();

    const placeholder = size && size.width && size.width > 500
        ? "E.g.: The main character makes an unexpected discovery"
        : "E.g.: The story is about love"
    ;

    useEffect(() => {
        !isMobile && inputRef.current && inputRef.current.focus();
    }, [inputRef]);


    function onAddLabel() {
        setSubmittedLabel(label);
        props.onAddLabel && props.onAddLabel(label);
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={inputRef}
                open={props.open}
                onClose={props.setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 sm:mt-0 sm:ml-4 text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Add label
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                <i>You are about to add a new label to the system.</i>
                                                <div className={'flex flex-row items-center'}>
                                                    <div className={'mr-4 my-4'}>
                                                        <ul className={'list-disc pl-4'}>
                                                            <li className={'my-2'}>
                                                                Thousands of texts and millions of words will be analyzed to see how well they match it.
                                                            </li>
                                                            <li className={'my-2'}>
                                                                Going through the complete library can take many days.
                                                            </li>
                                                            <li className={'my-2'}>
                                                                However, intermediate results will start appearing already
                                                                <span className={'whitespace-nowrap font-medium mx-1'}>
                                                                    within a few minutes
                                                                </span>
                                                                <Link to={'/account/labels'} target={'_blank'}
                                                                      className={'underline'}>here</Link>.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className={'mx-4'}>
                                                        <HourglassEmptyIcon color={'secondary'} fontSize={'large'}/>
                                                    </div>
                                                </div>
                                            </p>
                                            {!currentUser &&
                                            <div
                                                className={'flex flex-row items-center justify-center align-middle mb-8'}>
                                              <div
                                                  className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                              </div>
                                              <p className="text-sm text-gray-500 ml-3 mr-6">
                                                Registered users have access to more computational resources.
                                                Get results faster and track the progress by
                                                <Link to={'/signin'} className={'ml-1 mr-0.5 underline'}>signing
                                                  in</Link> first.
                                              </p>
                                            </div>
                                            }

                                            <form onSubmit={(event) => {
                                                event.preventDefault();
                                                onAddLabel();
                                            }} className={'sm:pr-4 sm:mb-2 mt-2'}>
                                                <div className="shadow flex">
                                                    <input className="w-full rounded p-2 sm:pl-4" type="text"
                                                           maxLength={100}
                                                           placeholder={placeholder}
                                                           onChange={(e) => setLabel(e.target.value)}
                                                           ref={inputRef}>
                                                    </input>
                                                </div>
                                                {label.length > 0 &&
                                                <div className={"text-xs text-gray-500 my-2"}>
                                                    {100 - label.length} characters left
                                                </div>
                                                }
                                                {label.length === 0 &&
                                                <div className={"text-xs text-gray-500 my-2"}>
                                                  100 characters max
                                                </div>
                                                }
                                                {submittedLabel &&
                                                <AddLabelResult label={submittedLabel || ''}
                                                                callback={e => {
                                                                    console.log('callback');
                                                                    if (!e) {
                                                                        props.setOpen(false);
                                                                        setLabel('');
                                                                        setSubmittedLabel(undefined);
                                                                    } else {
                                                                        console.log(e);
                                                                    }
                                                                }}/>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-600"
                                    onClick={() => onAddLabel()}
                                >
                                    Add
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => props.setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}