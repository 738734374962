import {FlexibleWidthXYPlot, Highlight, VerticalBarSeries, XAxis} from "react-vis";
import {useState} from "react";
import {useWindowSize} from "./utils";
import {isMobile} from "react-device-detect";

type TimeRange = {
    from?: number;
    to?: number;
}

type HistogramProps = {
    data: { "x": number, "y": number }[];
    lastDrawLocation?: { from?: number, to?: number };
    setLastDrawLocation?: (range: TimeRange) => void;
    updating?: boolean
}

function Histogram(props: HistogramProps) {
    const [lastDrawLocation, _setLastDrawLocation] = useState<TimeRange>(props.lastDrawLocation || {
        from: undefined,
        to: undefined
    });

    function setLastDrawLocation(timeRange: TimeRange) {
        console.log(timeRange);
        props.setLastDrawLocation && props.setLastDrawLocation(timeRange) || _setLastDrawLocation(timeRange);
    }

    function resetTimeRange() {
        setLastDrawLocation({from: undefined, to: undefined});
    }

    const zoomedIn = typeof lastDrawLocation.from !== 'undefined';
    const size = useWindowSize();
    const filteredData = props.data.sort((a, b) => a.x < b.x ? -1 : 1)
        .filter(a => a.x >= (lastDrawLocation.from || 0) && a.x <= (lastDrawLocation.to || 9999));

    const totalTicks = Math.min(Object.values(props.data).length,
        (size.width && size.width > 500)
            ? Math.min(8,
            lastDrawLocation.to &&
            lastDrawLocation.from &&
            Math.ceil(lastDrawLocation.to - lastDrawLocation.from) || 8)
            : 4);
    return (
        <div className={'flex flex-col justify-center my-4'}>
            <div className={'w-full'}>
                <FlexibleWidthXYPlot height={140}
                                     margin={{left: 15, right: 15}}
                                     xDomain={
                                         lastDrawLocation && lastDrawLocation.from && lastDrawLocation.to && [
                                             lastDrawLocation.from,
                                             lastDrawLocation.to
                                         ]
                                     }
                >
                    <VerticalBarSeries barWidth={0.1} data={filteredData}/>
                    <XAxis
                        tickTotal={totalTicks}
                        tickFormat={(tick: any) => `${tick}`}
                    />
                    <Highlight
                        enableY={false}
                        onBrushEnd={(area: { left?: number, right?: number } | null) => {
                            area && setLastDrawLocation({from: area.left, to: area.right})
                        }}
                        drag={false}
                        onDrag={(area: { left?: number, right?: number } | null) => area && setLastDrawLocation({
                            'from': lastDrawLocation.from && area.right && area.left && lastDrawLocation.from - (area.right - area.left),
                            'to': lastDrawLocation.to && area.right && area.left && lastDrawLocation.to - (area.right - area.left),
                        })
                        }
                    />
                </FlexibleWidthXYPlot>
            </div>
            <div className={"flex justify-center"}>
                <div onClick={resetTimeRange}
                     className={"cursor-pointer text-sm"}
                     style={{display: zoomedIn && !props.updating ? 'block' : 'none'}}>
                    reset zoom
                </div>
                <div style={{display: !isMobile && !zoomedIn && !props.updating ? 'block' : 'none'}}>
                    zoom in on a time range
                </div>
            </div>
        </div>
    );
}

export default Histogram;