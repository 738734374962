import React from "react";
import {Link} from "react-router-dom";
import SearchContainer from "./SearchContainer";
import {useLocalStorage} from "./utils";


function LinkToSearch(props: { to: string }) {
    return (
        <Link to={`/search?q=${props.to}`}>
            <div className={'flex flex-row place-items-center'}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hidden sm:block mr-2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
                <span className={"hover:underline underline-style-dotted"}>
                    &ldquo;{props.to}&rdquo;
                </span>
            </div>
        </Link>
    );
}

function FeatureDescription(props: { children: any, title: string }) {
    return (
        <div className={"flex md:flex-col pr-4 mt-4 md:mt-0 md:pt-4 md:p-6 md:bg-white md:rounded-lg md:shadow-xl"}>
            <div className={"w-1/2 md:w-auto font-medium md:pt-2 pr-4"}>{props.title}</div>
            <div className={"w-1/2 md:w-auto text-base pt-0.5 md:pt-6"}>
                {props.children}
            </div>
        </div>
    );
}

function Home() {
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    return (
        <div className={"md:shadow-xl clear-both bg-white"}>
            <div className="mx-auto my-4 sm:mt-6 md:pt-6 text-4xl sm:text-5xl font-base md:px-6">
                Find literature by narrative elements
            </div>

            <div className={"flex flex-col lg:flex-row flex-shrink-0 md:px-6"}>
                <div className={"text-2xl font-base md:pt-4 lg:pt-8 lg:w-1/2"}>
                    <div className={'sm:my-4 md:my-6 lg:my-8 hidden sm:block'}>
                        <span className={'font-bold'}>text.study</span> is a tool for literary research.
                    </div>
                    <div className={'md:mt-2 lg:mt-8 hidden sm:block'}>
                        Find texts by their <span className={"whitespace-nowrap"}>literary characteristics</span>.
                    </div>
                    <div className={"visible sm:hidden"}>
                        <span>text.study</span> is a tool for literary research.
                        Find texts by their literary characteristic.
                    </div>
                </div>
                <div className={'pt-8 lg:w-1/2'}>
                    <div
                        className={"grid grid-cols-1 place-content-between min-h-32 mt-4 text-xl md:justify-items-end lg:justify-items-start"}>
                        <Link to={"/search?q=Short story with a strong female character"} className={"my-2"}>
                            <span className={"hover:underline"}>
                                &ldquo;Short story with a strong female character&rdquo;
                            </span>
                        </Link>
                        <Link to={"/search?q=Detective story where justice does not prevail"} className={"my-2"}>
                            <span className={"hover:underline"}>
                                &ldquo;Detective story where justice does not prevail&rdquo;
                            </span>
                        </Link>
                        <Link to={"/search?q=Story from the 19th century with a priest as a main character"}
                              className={"my-2"}>
                            <span className={"hover:underline"}>
                                &ldquo;19th century story with a priest as a main character&rdquo;
                            </span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={"overflow-auto sm:flex place-content-center md:place-content-start items-center mx-auto px-1 sm:px-0 md:pr-4 md:px-0 pb-6 mt-6 pt-6 md:bg-gray-100"}>
                <div className={"mr-4 sm:mr-0 sm:w-1/4 text-lg whitespace-nowrap text-center font-medium pb-4 sm:pb-0"}>
                    Give it a try:
                </div>
                <SearchContainer className={'sm:w-3/4 lg:w-2/4 sm:my-4 sm:pr-1 md:pr-0 lg:pr-1'}/>
            </div>
            <div className={"lg:flex py-6 pr-4 text-lg items-center bg-gray-50"}>
                <div className={"lg:w-1/4 text-2xl font-medium pl-4 md:text-center md:p-4"}>
                    Features
                </div>
                <div className={"lg:w-3/4 pl-4 lg:pl-0 my-4 flex"}>
                    <div className={'md:grid md:grid-cols-3 md:gap-5'}>
                        <FeatureDescription title={"Library"}>
                            118,000 plots of books, films and video games from English Wikipedia.
                            About 60 million words.
                        </FeatureDescription>
                        <FeatureDescription title={"Historical use"}>
                            See how often a trope has been used throughout the history.
                            Zoom in on search results from a specific time period.
                        </FeatureDescription>
                        <FeatureDescription title={"Writers' themes"}>
                            See the main themes common to the body of work of a given author.
                        </FeatureDescription>
                        <FeatureDescription title={"Fast search"}>
                            Quickly search using precomputed features.
                        </FeatureDescription>
                        <FeatureDescription title={"In-depth analysis"}>
                            Run computationally expensive analyses of texts with custom criteria specific
                            to your interests using distributed computing infrastructure.
                        </FeatureDescription>
                        <FeatureDescription title={"Corpus insights"}>
                            See how common a specific feature / narrative element is within the dataset.
                        </FeatureDescription>
                        <FeatureDescription title={"Explained results"}>
                            Let the search engine explain its results using a GPT-3 integration.
                        </FeatureDescription>
                        <FeatureDescription title={"Crowdsourcing"}>
                            Provide feedback on the search engine results and contribute to a new public dataset
                            on which better text understanding models will be trained.
                        </FeatureDescription>
                        <FeatureDescription title={"Data export"}>
                            Take out all the data you have contributed to the system with one click.
                        </FeatureDescription>
                    </div>
                </div>
            </div>


            <div className={"lg:flex text-lg py-6 pb-8 pr-4 items-center bg-gray-100"}>
                <div className={"w-full lg:w-1/4 text-2xl font-medium pl-4 md:text-center md:p-4"}>
                    Personalization <span className={'whitespace-nowrap'}>& Community</span>
                </div>
                <div className={"w-full lg:w-3/4 pl-4 lg:pl-0 "}>
                    <div className={"my-4"}>
                        You can get search results that are more relevant to your own research by
                        {isSignedIn
                            ? <span className={"mx-1"}>signing in</span>
                            : <Link to="/signin" className={"mx-1 underline"}>signing in</Link>
                        }
                        and <i>defining the labels</i> that are relevant to your topic of interest.
                    </div>
                    <div className={"w-full sm:flex items-center mt-8"}>
                        <div className={"sm:w-1/3"}>
                            <div className={"font-medium"}>Interested in animal history?</div>
                        </div>
                        <div className={"sm:w-2/3 italic mt-4 sm:mt-0 sm:pl-4"}>
                            <LinkToSearch to={"there is an animal in the story"}/>
                            <LinkToSearch to={"an animal plays a major role in the story"}/>
                            <LinkToSearch to={"the story is about human-animal relationships"}/>
                        </div>
                    </div>
                    <div className={"w-full sm:flex items-center mt-4"}>
                        <div className={"sm:w-1/3"}>
                            <div className={"font-medium"}>Studying committed literature?</div>
                        </div>
                        <div className={"sm:w-2/3 italic mt-4 sm:mt-0 sm:pl-4"}>
                            <LinkToSearch to={"someone wants to change the society in the story"}/>
                            <LinkToSearch to={"there is a social conflict in the story"}/>
                            <LinkToSearch to={"the story is about injustice"}/>
                        </div>
                    </div>
                    <div className={"w-full sm:flex items-center mt-4"}>
                        <div className={"sm:w-1/3"}>
                            <div className={"font-medium"}>Looking for apocalyptic motifs?</div>
                        </div>
                        <div className={"sm:w-2/3 italic mt-4 sm:mt-0 sm:pl-4"}>
                            <LinkToSearch to={"there is a prophet in the story"}/>
                            <LinkToSearch to={"many people die in the story"}/>
                            <LinkToSearch to={"the story is about the end of the world"}/>
                        </div>
                    </div>
                    <div className={"sm:my-2 pt-6"}>
                        Good parameters improve the search results for you and everybody else.
                    </div>
                </div>
            </div>

            <div className={"lg:flex py-6 pb-12 pr-4 text-lg items-center bg-gray-50"}>
                <div className={"lg:w-1/4 text-2xl font-medium pl-4 md:text-center md:p-4"}>
                    Roadmap
                </div>
                <div className={"lg:w-3/4 flex pl-4 lg:pl-0"}>
                    <div>
                        <div className={"text-lg mt-4 mb-6"}>
                            <p>This is an <i>early preview</i> - we are working on integrating more powerful text
                            understanding models, processing more texts in multiple languages,
                            improving performance and adding new features.
                            </p>
                        </div>
                        <div className={'md:grid md:grid-cols-3 md:gap-4'}>
                            <FeatureDescription title={"Texts"}>
                                Growing the literature database. Integration of full texts from Project Gutenberg.
                            </FeatureDescription>
                            <FeatureDescription title={"Languages"}>
                                Support for more languages, including less represented ones, such as Hebrew and Yiddish.
                            </FeatureDescription>
                            <FeatureDescription title={"Understanding"}>
                                Deeper integration of more powerful natural language processing models, such as GPT-3.
                            </FeatureDescription>
                            <FeatureDescription title={"Style"}>
                                Analysis of stylistic aspects of texts.
                            </FeatureDescription>
                            <FeatureDescription title={"Performance"}>
                                Run analyses on dedicated infrastructure for faster processing.
                            </FeatureDescription>
                            <FeatureDescription title={"Transparency"}>
                                Highlighting sentences in the text that are particularly relevant to the search request.
                            </FeatureDescription>
                            <FeatureDescription title={"Focus"}>
                                Define subsets of the library relevant to your research based on text metadata and content.
                            </FeatureDescription>
                            <FeatureDescription title={"Precision"}>
                                Define multi-step analysis flows for guiding the algorithm's understanding process.
                            </FeatureDescription>
                            <FeatureDescription title={"Efficiency"}>
                                Optimizing the distribution of tasks between simpler and more expensive language models.
                            </FeatureDescription>
                            <FeatureDescription title={"Evaluation"}>
                                Conduct a user study and measure the search engine performance in a systematic way.
                            </FeatureDescription>
                            <FeatureDescription title={"Open Data"}>
                                Release the dataset of user annotations of text classification correctness.
                            </FeatureDescription>
                            <FeatureDescription title={"Finetuning"}>
                                Train more accurate models on the crowdsourced user feedback.
                            </FeatureDescription>

                        </div>
                        <p className={'mt-8'}>
                            <b>text.study</b> is not just an application - it's an environment for
                            quickly experimenting with new methods and approaches to text understanding and deploying
                            them for humanities researchers to evaluate and use. Developing
                            the system in close collaboration with our users is an important principle we believe in.
                        </p>
                    </div>
                </div>
            </div>

            <div className={"lg:flex py-6 pr-4 text-lg items-center bg-gray-100"}>
                <div className={"lg:w-1/4 text-2xl font-medium pl-4 md:text-center md:p-4"}>
                    About
                </div>
                <div className={"lg:w-3/4 flex pl-4 lg:pl-0 "}>
                    <div>
                        <div className={"text-lg mt-4 mb-6 md:pr-8"}>
                            <span className={'font-bold'}>text.study</span> was inspired by a challenge
                            literary scholar Carmen Reichert encountered when starting her project
                            on concepts of justice in Jewish literatures and their reflection of cultural change.
                            Selecting a representative set of texts for further analysis from literatures
                            spanning multiple languages and centuries would be impractical due to the
                            sheer volume of potentially relevant texts.

                            <div className={'mt-4'}>
                                Recent advances in the field of natural language processing
                                gave us hope that new methods and tools can be developed that would
                                allow researchers like herself to tackle questions previously
                                inaccessible to literary scholars, cultural scientists and historians.
                            </div>
                            <div className={'mt-4'}>
                                This website is an exploration of this approach.
                                Our technology is not yet ready to analyze Yiddish novels,
                                but this website is a first step in that direction.
                            </div>
                            <div className={'mt-4'}>
                                Technical conception and implementation by Vladimir Viro and Alexander Mirsky of
                                <a href={'https://www.peachnote.de'} target='_blank' className={'underline ml-1'}>Peachnote</a>.
                                Many thanks to Phoebe Brunt
                                for her feedback and helpful suggestions during the development!
                            </div>
                            <div className={'mt-4'}>
                                <span className={'font-bold'}>text.study</span> is using the
                                <a href={'https://egi.eu'} target='_blank' className={'underline mx-1'}>EGI</a>
                                infrastructure with the dedicated support of the CESNET-MCC providers.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;