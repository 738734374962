import {useParams} from "react-router-dom";
import Histogram from "./Histogram";
import usePostAuthorAnalysisService from "./services/usePostAuthorAnalysisService";
import LabelMatchItem from "./LabelMatchItem";
import BookItem from "./BookItem";
import AddLabel from "./AddLabel";
import React, {useState} from "react";
import SearchContainer from "./SearchContainer";
import {LinearProgress} from "@material-ui/core";


function AuthorPage() {

    const {authorIdentifier} = useParams<{ authorIdentifier: string }>();

    document.title = authorIdentifier + ' - Text.Study' || "Text.Study: Search by Narrative Elements";

    const service = usePostAuthorAnalysisService(authorIdentifier);

    const [timeRange, setTimeRange] = useState<{from?: number, to?: number}>({from: undefined, to: undefined});

    const labelMatches = service.status === 'loaded' &&
        service.payload.labels.map(labelMatch => (
            <LabelMatchItem labelMatch={labelMatch} showStats={false} key={labelMatch.label_id}/>
        ));
    const bookMatches = service.status === 'loaded' &&
        service.payload.books
            .filter(bookMatch => typeof timeRange.from === 'undefined' || typeof timeRange.to === 'undefined' ||
                (bookMatch.pubyear && bookMatch.pubyear > timeRange.from && bookMatch.pubyear < timeRange.to))
            .map(book => (
            <BookItem book={book} hideAuthor={true} key={book.book_id}/>
        ))

    let data = service.status === 'loaded' ?
        service.payload.books
            .filter(bookMatch => typeof bookMatch.pubyear !== 'undefined' && bookMatch.pubyear && bookMatch.pubyear > 0)
            .reduce(function (acc: any, obj) {
                let key: number = obj["pubyear"] || 0;
                if (!key) return acc;
                if (!acc[key]) acc[key] = 0;
                acc[key] += 1;
                return acc;
            }, {})
        : {}
    data = Object.entries(data).map(entry => ({x: -(-entry[0]), y: entry[1]}))

    return (
        <div className={'bg-white sm:rounded sm:px-4 sm:py-2 sm:my-4'}>
            <div className={"md:flex w-full justify-center"}>
                <SearchContainer/>
            </div>
            <div className={"flex justify-center"}>
                <span>Texts by</span>
                <span className={"pl-1"}>{authorIdentifier}</span>
            </div>
            {service.status === 'loading' &&
            <div>
              Loading...
              <LinearProgress />
            </div>
            }
            {service.status === 'loaded' && data.length > 0 &&
            <Histogram data={data} lastDrawLocation={timeRange} setLastDrawLocation={setTimeRange}/>
            }
            {service.status === 'loaded' &&
            <div className={"w-full md:flex md:justify-center"}>
              <div className={"w-full"}>
                <div className={"font-bold"}>Texts</div>
                  {bookMatches}
              </div>
              <div className={"w-full"}>
                <div className={"font-bold"}>Relevant labels</div>
                  {labelMatches}
              </div>
            </div>
            }
            {service.status === 'error' && (
                <div>Backend error. {JSON.stringify(service.error)}</div>
            )}
            {service.status !== 'loading' && service.status !== 'updating' &&
            <div className={"mx-auto md:flex md:justify-center mt-4"}>
              <AddLabel/>
            </div>
            }
        </div>
    )
}

export default AuthorPage;