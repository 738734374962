import {CircularProgress} from "@material-ui/core";
import {ExclamationIcon} from "@heroicons/react/outline";
import React from "react";
import {Service} from "./types/Service";

export default function ExplanationPane(props: { matchExplainService: Service<any> }) {

    let negative = false;
    let label = "";

    if (props.matchExplainService.status === 'loaded') {
        if (props.matchExplainService.payload.choices[0].text.indexOf("NO") >= 0 ||
            props.matchExplainService.payload.choices[0].text.indexOf("does not occur") >= 0 ||
            props.matchExplainService.payload.choices[0].text.indexOf("text does not contain") >= 0
        ) {
            negative = true;
        }

        if (props.matchExplainService.payload.choices[0].text.indexOf("NO") >= 0) {
            label = 'The plot element does not occur in the text.';
        } else {
            let explanation = props.matchExplainService.payload.choices[0].text;
            if (props.matchExplainService.payload.choices[0].finish_reason !== "stop") {
                explanation += "\u2026";
            }
            label = explanation;
        }
    }

    return <div
        className={"p-4 bg-blue-50 flex flex-row items-center justify-center align-middle sm:w-sm max-w-lg"}>
        <div>
            {((props.matchExplainService.status === "loaded" && negative) || props.matchExplainService.status === "error") &&
            <ExclamationIcon className="h-6 w-6 text-red-600 pt-1 mr-3" aria-hidden="true"/>
            }
        </div>
        <div>
            {props.matchExplainService.status === "loading" &&
            <div className={"flex flex-col text-center items-center"}>
              <CircularProgress/>
              <div>Looking for explanation{"\u2026"}</div>
            </div>
            }
            <div>{label}</div>
            {label && <div className={'text-sm italic mt-2 text-right'}>Analysis by GPT-3</div>}
            {props.matchExplainService.status === "error" &&
                <div className={"mr-2"}>
                    Service error
                </div>
            }
        </div>
    </div>;
}
