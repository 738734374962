import fb from "./firebase";
import {useObject} from "react-firebase-hooks/database";
import React, {useEffect} from "react";
import AddLabel from "./AddLabel";
import {Label} from "./types/Analysis";
import LabelTable from "./LabelTable";
import {useHistory} from "react-router-dom";
import {compareProperty, useLocalStorage} from "./utils";


export const UserLabels = () => {
    let currentUser = fb.auth().currentUser;
    let myUserId = currentUser && currentUser.uid;

    let labelRef = fb.database().ref('user-labels/' + myUserId + '/list');
    const [snapshot, loading, error] = useObject(labelRef);

    let history = useHistory();
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    useEffect(() => {
        if (!isSignedIn && !currentUser) {
            history.push('/signin')
        }
    }, [isSignedIn, currentUser]);

    if (!myUserId) {
        return null;
    }

    const rows = snapshot && Object.entries(snapshot.toJSON() || {}).map(
        (kv: [string, Label]) => kv[1]
    ).sort((a, b) => compareProperty(a.total_word_count, b.total_word_count)) || [];

    return (
        <div>
            <div className={'my-2 text-right'}>
                <AddLabel/>
            </div>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Loading...</span>}
            {snapshot &&
            <div className={"w-full md:flex md:flex-col md:justify-center"}>
                <LabelTable rows={rows} />
            </div>
            }
        </div>
    )
}