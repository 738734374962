import React, {useState} from "react";
import usePostAddLabelService from "./services/usePostAddLabelService";
import {CircularProgress} from "@material-ui/core";
import AddLabelModal from "./AddLabelModal";


function AddLabelResult(props: { submittedAnnotation: string }) {
    const service = usePostAddLabelService(props.submittedAnnotation);
    return (
        <div>
            {service.status === 'loading' && <CircularProgress />}
            {service.status === 'loaded' && <div>Added</div>}
            {service.status === 'error' && <div>Error</div>}
        </div>
    )
}


function AddLabel() {
    const [inFocus, setInFocus] = useState(false);


    return (
        <div>
            <button
                onClick={() => setInFocus(true)}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-600">
                Add label
            </button>
            <AddLabelModal open={inFocus} setOpen={setInFocus}/>
        </div>
    );
}

export default AddLabel;