import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {isMobile} from 'react-device-detect';
import {useHistory, useLocation} from "react-router-dom";
import SearchButton from "./SearchButton";
import {useDebounce} from "./utils";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchContainer(props: {
    className?: string,
    onDebouncedQueryChanged?: (query: string) => void,
    onQueryChanged?: (query: string) => void,
    disabled?: boolean,
    query?: string,
    inFocus?: boolean,
    onSubmit?: () => void
}) {

    const [edited, setEdited] = useState(false);
    const [query, setQuery] = useState(props.query || '');

    const debouncedSearchTerm = useDebounce(query, 1000);

    useEffect(
        () => {
            if (debouncedSearchTerm) {
                props.onDebouncedQueryChanged && props.onDebouncedQueryChanged(debouncedSearchTerm);
            } else {
                props.onDebouncedQueryChanged && props.onDebouncedQueryChanged('');
            }
        },
        [debouncedSearchTerm]
    );

    let initialQuery = useQuery();

    const q = edited ? query : (query || initialQuery.get("q") || '');

    let history = useHistory();

    const inputProps = {
        placeholder: 'E.g.: The story has a strong main character',
        value: q,
        onChange: (params: ChangeEvent<HTMLInputElement>) => {
            setEdited(true);
            setQuery(params.target.value);
            props.onQueryChanged && props.onQueryChanged(params.target.value);
        },
    };

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (query.length > 0) {
            props.onSubmit && props.onSubmit();
            if (!props.onSubmit) {
                const new_path = '/search' + (query ? ('?q=' + query) : '');
                history.push(new_path);
            }
        }
    }

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        !isMobile && props.inFocus && inputRef.current && inputRef.current.focus();
    }, [inputRef]);

    return (
        <form onSubmit={onSubmit} className={props.className || 'sm:w-3/4 sm:my-4'}>
            <div className="shadow flex">
                <input className="w-full rounded-l p-2 sm:pl-4" type="text" {...inputProps} ref={inputRef}>
                </input>
                <SearchButton disabled={props.disabled}/>
            </div>
        </form>
    )
}

export default SearchContainer;