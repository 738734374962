import React from "react";

function SearchButton(props: {disabled?: boolean}) {
    return (
        <button disabled={props.disabled}
            className="bg-white w-auto flex justify-end items-center text-blue-500 disabled:text-gray-200 p-2 hover:text-blue-400">
            <i className="material-icons">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"/>
                </svg>
            </i>
        </button>
    )
}

export default SearchButton;