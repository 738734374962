import {Link} from "react-router-dom";
import Wrapper from "./lib/react-wiki-preview/Wrapper";
import {Book} from "./types/Analysis";
import {Tooltip} from "@material-ui/core";
import {wikiLogo} from "./lib/react-wiki-preview/Content";
import {decodeHtml} from "./utils";



function BookItem(props: { book: Book, hideAuthor?: boolean }) {
    return (
        <div key={props.book.book_id} className={"group flex my-4 mr-2 justify-between items-center bg-gradient-to-r hover:from-gray-100"}>

            <div className={"inline-block lg:flex lg:flex-row min-w-1/2 border-l-2 pl-2"}>
                <Link to={`/t/${encodeURI(props.book.title + (props.book.author ? ", " + props.book.author : ""))}`}
                      className={"text-black sm:whitespace-nowrap"}>
                    {decodeHtml(props.book.title)}
                </Link>
                <div className={'sm:whitespace-nowrap flex flex-row'}>
                    {props.book.author && !props.hideAuthor && (
                        <div className={'flex flex-row'}>
                            <br className={'sm:hidden'}/>
                            <span className={'hidden lg:block'}>, by</span>
                            <Link to={'/author/' + props.book.author}
                                  className={'text-gray-400 font-bold lg:ml-1 border-0'}>{props.book.author}</Link>
                        </div>
                    )}
                    {props.book.pubyear &&
                    <span className={"text-sm leading-6 pl-1"}>({props.book.pubyear})</span>}
                </div>
            </div>
            
            {props.book.wikipedia_title &&
            <Tooltip title="Open Wikipedia article in a new page" placement="bottom-start"
                     arrow disableTouchListener={true}>
              <div className={'invisible group-hover:visible ml-2 sm:ml-4 mt-2 sm:mt-0'}>
                <Wrapper keyword={props.book.wikipedia_title}>
                    {wikiLogo('light')}
                </Wrapper>
              </div>
            </Tooltip>
            }
        </div>
    )
}

export default BookItem;