import {Link} from "react-router-dom";
import {LabelMatch} from "./types/Analysis";
import numeral from "numeral";
import {Tooltip} from "@material-ui/core";
import {decodeHtml} from "./utils";

function percentageToHsl(percentage: number, hue0: number, hue1: number): string {
    let hue = (percentage * (hue1 - hue0)) + hue0;
    return 'hsl(' + hue + ', 100%, 50%)';
}

function LabelMatchItem(props: {
    labelMatch: LabelMatch,
    showStats?: boolean,
    className?: string,
    underlineLink?: boolean,
    onExplain?: (elt: HTMLDivElement) => void,
}) {
    const outerClassName = (props.className ? props.className : "flex group justify-content-start px-4 my-4");
    return (
        <div key={props.labelMatch.label_id} className={outerClassName}>
            <Link to={`/l/${encodeURI(props.labelMatch.label)}`} className={"flex" + (props.underlineLink ? ' hover:underline' : '')}>
                <span className={"inline-block w-0.5 min-w-0.5 h-full mr-4"}
                     style={{backgroundColor: percentageToHsl(Math.min(1, props.labelMatch.score),0, 120)}}>
                </span>
                <span>{decodeHtml(props.labelMatch.label)}</span>
            </Link>
            {props.showStats && props.labelMatch.done &&
            <div className={'invisible group-hover:visible ml-6'}>
                {Math.round(props.labelMatch.done * 10000) / 100} % done
            </div>
            }
            {props.showStats && props.labelMatch.total_word_count &&
            <div className={'invisible group-hover:visible ml-3'}>
                {numeral(props.labelMatch.total_word_count).format('0.0a')} words
            </div>
            }
            {props.onExplain &&
            <div className={'invisible group-hover:visible ml-4 underline cursor-pointer'}
                 onClick={(element) => props.onExplain && props.onExplain(element.currentTarget)}>
              <Tooltip title="Explain how this label matches the given text" placement="top" arrow>
                <div>[explain]</div>
              </Tooltip>
            </div>
            }
        </div>
    )
}

export default LabelMatchItem;