import fb from "./firebase";
import {Link, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useLocalStorage} from "./utils";

export default function Header() {
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    const { pathname, search } = useLocation();
    const [loggedOut, setLoggedOut] = useState(false);
    const [labelCount, setLabelCount] = useState(0);

    let history = useHistory();

    const targetUrl = pathname + (search || '');
    const signInUrl = '/signin' + (pathname !== '/' ? '?to=' + encodeURIComponent(targetUrl) : '')

    let currentUser = fb.auth().currentUser;
    let myUserId = currentUser && currentUser.uid;

    useEffect(() => {
        const unregisterAuthObserver = fb.auth().onAuthStateChanged(user => {
            if (user && !user.isAnonymous) {
                currentUser = user;
                myUserId = currentUser && currentUser.uid;
                setIsSignedIn(true);
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    let labelRef = fb.database().ref('user-labels/' + myUserId + '/count');

    useEffect(() => {
        const onDataChange = labelRef.on('value', (snapshot: fb.database.DataSnapshot) => {
            const v = snapshot && snapshot.val() || 0;
            setLabelCount(v);
        });
        return () => labelRef.off("value", onDataChange);
    });

    useEffect(() => {
        if (loggedOut && !isSignedIn) {
            history.push('/')
        }
    }, [loggedOut, isSignedIn, history]);

    return (
        <div className={'w-full'}>
            {pathname !== '/' &&
            <Link to="/" className={"md:mr-4 mt-4 float-left bg-opacity-70 sm:bg-gray-50 pb-0.5 rounded sm:px-3"}>text.study</Link>
            }
            {((pathname !== '/' && pathname !== '/search') || isSignedIn) &&
            <div className="mt-4 float-right flex bg-opacity-70 sm:bg-white pb-0.5 rounded-r pl-3">
                {pathname !== '/search' && pathname !== '/' &&
                <Link to={'/search'} className={'mx-4 sm:mr-7 flex justify-center'}>
                  <button
                      className="w-auto text-gray-500 pt-1 hover:text-gray-400">
                    <i className="material-icons">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                           fill="currentColor">
                        <path fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"/>
                      </svg>
                    </i>
                  </button>
                </Link>
                }
                {isSignedIn &&
                <Link to={'/account/labels'} className={'mx-4 sm:mr-8'}>
                  my labels <span style={{visibility: labelCount ? 'visible' : 'hidden'}}>({labelCount})</span>
                </Link>
                }
                {isSignedIn && pathname !== '/account' &&
                <Link to={'/account'} className={'sm:mr-8'}>
                  account
                </Link>}
                {pathname === '/account' && <a onClick={() => {
                    let currentUser = fb.auth().currentUser;
                    let myUserId = currentUser && currentUser.uid;
                    let onlineStateRef = fb.database().ref('users-online/' + myUserId);
                    onlineStateRef.set(false);
                    fb.auth().signOut();
                    setIsSignedIn(false);
                    setLoggedOut(true);
                }} className={'cursor-pointer sm:mr-8'}>sign out</a>
                }
            </div>}

            {!isSignedIn && pathname !== '/signin' &&
            <Link to={signInUrl} className={"mt-4 float-right bg-white pb-0.5 pl-3 sm:pr-3"}>sign in</Link>
            }
        </div>
    );
}