import fb from "../firebase"

import {useEffect, useRef, useState} from 'react';
import { Service } from '../types/Service';
import { TextSearch } from '../types/Analysis';
import { useHistory } from "react-router-dom";
import Hashes from 'jshashes';

const searchTexts = process.env.NODE_ENV === 'production' ?
    fb.app().functions('europe-west1').httpsCallable('searchTexts') :
    fb.app().functions().httpsCallable('searchTexts');

const usePostSearchService = (query: string, yearFrom?: number, yearTo?: number, labelIds?: number[], doSearch?: boolean) => {
    const [result, setResult] = useState<Service<TextSearch>>({
        status: 'loading', request: {query, yearFrom, yearTo, labelIds, doSearch}
    });
    let history = useHistory();
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        mountedRef.current = true;
        if (query.length === 0) {
            return;
        }

        if (result.status === 'loaded') {
            setResult({status: 'updating', prevPayload: result.payload, request: {query, yearFrom, yearTo, labelIds, doSearch}})
        } else {
            setResult({status: 'loading', request: {query, yearFrom, yearTo, labelIds, doSearch}})
        }

        const requestRepresentation = JSON.stringify({query, labelIds});
        const queryHash = new Hashes.SHA1().hex(requestRepresentation).substr(0, 16);

        let queryRef = fb.database().ref('search-results/' + queryHash);
        let onDataChange = (a: fb.database.DataSnapshot | null, b?: string | null | undefined) => {};
        if (!yearFrom && !yearTo) {
            onDataChange = queryRef.on('value', (snapshot: fb.database.DataSnapshot) => {
                const val = snapshot.val();
                if (val) {
                    setResult({status: 'loaded', payload: val, request: {query, yearFrom, yearTo, labelIds, doSearch}});
                }
            });
        }

        if (!doSearch) {
            return;
        }

        const data = yearFrom && yearTo
            ? { query: query, label_ids: labelIds, year_from: Math.round(yearFrom), year_to: Math.round(yearTo) }
            : { query : query, label_ids: labelIds };

        searchTexts(data)
            .then((res) => {
                setResult({status: 'loaded', payload: res.data, request: {query, yearFrom, yearTo, labelIds, doSearch}})

                if (!yearTo && !yearTo) {
                   queryRef.set(res.data, (e: Error | null) => e && console.error(e)).catch(e => e && console.error(e));
                }
                if (res.data.query && mountedRef.current) {
                    const label = res.data.query;
                    const new_path = '/search' + (label.query ? ('?q=' + label.query) : '');
                    history.push(new_path);
                }
            })
            .catch((error) => {
                console.error(error);
                setResult({ status: 'error', error, request: {query, yearFrom, yearTo, labelIds, doSearch} })
            });
        return () => {
            mountedRef.current = false;
            queryRef.off('value', onDataChange);
        };
    }, [query, yearFrom, yearTo, JSON.stringify(labelIds), doSearch, history]);

    return result;
};

export default usePostSearchService;