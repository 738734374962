import fb from "../firebase"

import {useEffect, useRef, useState} from 'react';
import {Service} from '../types/Service';
import {md5} from "../utils";

const explainMatch = process.env.NODE_ENV === 'production' ?
    fb.app().functions('europe-west1').httpsCallable('explainMatch') :
    fb.app().functions().httpsCallable('explainMatch');

const usePostExplainMatchService = (textId?: number, query?: string, doSearch?: boolean) => {
    const request = {textId, query, doSearch};
    const [result, setResult] = useState<Service<any>>({
        status: 'loading', request
    });
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        mountedRef.current = true;
        if (!doSearch || !query || (query && query.length === 0)) {
            return;
        }

        setResult({status: 'loading', request});
        const path = 'explain-match-search-results/' + textId + '/' + md5(query, 16);
        console.log(path);
        let queryRef = fb.database().ref(path);

        let onDataChange = queryRef.on('value', (snapshot: fb.database.DataSnapshot) => {
            const val = snapshot.val();
            if (val) {
                console.log('set loaded', val)
                setResult({status: 'loaded', payload: val, request});
            } else {
                const data = {text_id: textId, query: query};
                console.log('fetching explanation', data)
                explainMatch(data)
                    .then((result) => {
                        console.log('got explanation', result);
                        queryRef.set(result.data, (e: Error | null) => e && console.error(e)).catch(e => e && console.error(e));
                        mountedRef.current && setResult({status: 'loaded', payload: result.data, request})
                    })
                    .catch((error) => {
                        console.error(error);
                        setResult({status: 'error', error, request})
                    });
            }
        });

        return () => {
            mountedRef.current = false;
            queryRef.off('value', onDataChange);
        };
    }, [query, textId, doSearch]);

    return result;
};

export default usePostExplainMatchService;