import fb from "./firebase";
import {FlexibleWidthXYPlot, VerticalBarSeries, XAxis, YAxis} from "react-vis";
import {useEffect, useState} from "react";
import Hashes from "jshashes";
import {useWindowSize} from "./utils";

export default function HistoricalUse(props: { label: string }) {
    const [data, setData] = useState<{ x: number; y: number; }[]>();
    const [minX, setMinX] = useState<number | undefined>();
    const [maxX, setMaxX] = useState<number | undefined>();

    const md5Hash = new Hashes.MD5().hex(props.label).substr(0, 12);

    let ref = fb.database().ref('label-stats/' + md5Hash + '/label_percentile_rate_by_year');

    useEffect(() => {
        const handler = ref.on('value', (snapshot: fb.database.DataSnapshot) => {
            const d = snapshot && Object.entries(snapshot.toJSON() || {}).map(
                (kv: [string, number]) => {
                    return {x: -(-(kv[0])), y: kv[1]};
                }
            ).filter((val) => val.x > 1700 && val.y < 100)
            .sort((v1, v2) => v1.x < v2.x ? -1 : 1);
            if (!d || d.length === 0) {
                return;
            }

            const minX = d.map((val) => val.x).reduce((prev, current) => {
                if (!prev) {
                    return current;
                } else {
                    return Math.min(prev, current);
                }
            });
            setMinX(minX);

            const maxX = d.map((val) => val.x).reduce((prev, current) => {
                if (!prev) {
                    return current;
                } else {
                    return Math.max(prev, current);
                }
            })
            setMaxX(maxX);
            let decades: { [id: number] : number; } = {};
            d.forEach(v => {
                const decade = Math.round(v.x / 10) * 10;
                if (decades[decade]) {
                    decades[decade] += v.y / 10;
                } else {
                    decades[decade] = v.y / 10;
                }
            });
            let d2 = Object.entries(decades).map(
                (kv: [string, number]) => {
                    return {x: -(-(kv[0])), y: kv[1]};
                });

            const dExt = [{x: (minX - 1), y: 0}, ...d2, {x: (maxX + 1), y: 0}];
            setData(dExt);
        });
        return () => ref.off('value', handler);
    }, [props.label]);

    const size = useWindowSize();

    const totalTicks = data && Math.min(data.length,
        (size.width && size.width > 500)
            ? Math.min(8,
            minX &&
            maxX &&
            Math.ceil(maxX - minX) || 8)
            : 4) || 1;

    if (data?.length === 0) {
        return (
            <div>
                The data will be available soon.
            </div>
        )
    }

    return (
        <FlexibleWidthXYPlot height={150} margin={{left: 15, right: 15}}>
            <VerticalBarSeries barWidth={1} data={data} />
            <XAxis tickFormat={(tick: any) => `${tick}`} tickTotal={totalTicks} />
            <YAxis />
        </FlexibleWidthXYPlot>
    );
}
