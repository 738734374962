import fb from "./firebase";
import {FlexibleWidthXYPlot, HorizontalGridLines, LineSeries, VerticalBarSeries, XAxis, XYPlot, YAxis} from "react-vis";
import {useEffect, useState} from "react";
import Hashes from "jshashes";

export default function LabelPercentiles(props: { label: string, size: number }) {
    const [data, setData] = useState<{ x: number; y: number; }[]>();

    const md5Hash = new Hashes.MD5().hex(props.label).substr(0, 12);

    let ref = fb.database().ref('label-stats/' + md5Hash + '/percentiles');

    useEffect(() => {
        const handler = ref.on('value', (snapshot: fb.database.DataSnapshot) => {
            const d = snapshot && Object.entries(snapshot.toJSON() || {}).map(
                (kv: [string, number]) => {
                    return {x: -(-(kv[0])), y: kv[1]};
                }
            ).sort((v1, v2) => v1.x < v2.x ? -1 : 1);

            setData(d);
        });
        return () => ref.off('value', handler);
    }, [])

    return (
        <FlexibleWidthXYPlot height={props.size} margin={{left: 15, right: 15}}>
            <VerticalBarSeries barWidth={1} data={data} yDomain={[0, 1]} />
        </FlexibleWidthXYPlot>
    );
}
