import {useEffect, useState} from "react";
import {BookMatch} from "./types/Analysis";
import {ItemType} from "./SearchPage";
import {
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import {Alert, IconContainerProps, Rating} from "@material-ui/lab";
import fb from "./firebase";


function extendedPercentileToPercents(percentile: number) {
    let a;
    if (percentile <= 99) {
        a = percentile;
    } else if (99 < percentile && percentile <= 108) {
        a = 99 + (percentile - 99) / 10;
    } else if (108 < percentile && percentile <= 117) {
        a = 99.9 + (percentile - 108) / 100;
    } else if (117 < percentile && percentile <= 127) {
        a = 99.99 + (percentile - 117) / 1000;
    } else {
        a = 100
    }
    return Math.round(a * 1000) / 1000;
}

const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Wrong',
    },
    2: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Unsure',
    },
    3: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Correct',
    }
};

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

type MatchEvaluation = {
    rating: number;
    label: string;
    textTitle: string;
    textAuthor?: string;
};

export function MatchLabelQuantiles(props: { bookMatch: BookMatch, labels: ItemType[], anonHash?: string }) {

    const [labelEvals, setLabelEvals] = useState<{[labelId: number]: MatchEvaluation}>();

    const [successOpen, setSuccessOpen] = useState<boolean>(false);
    const [errorOpen, setErrorOpen] = useState<boolean>(false);

    const handleSuccessClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return;
        setSuccessOpen(false);
    };
    const handleErrorClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return;
        setErrorOpen(false);
    };

    const currentUser = fb.auth().currentUser;
    const myUserId = currentUser && currentUser.uid;
    const labelUserId = myUserId ? myUserId : props.anonHash;

    let labelEvalRef = fb.database().ref('user-text-label-eval/' + labelUserId + '/' + props.bookMatch.book_id);
    let userEvalCountRef = fb.database().ref('user-settings/' + myUserId + '/stats/match_evaluations');

    useEffect(() => {
        labelEvalRef.get().then((snapshot) => {
            const v = snapshot.val();
            if (!v) return;
            setLabelEvals({...labelEvals, ...v})
        });
    }, [myUserId]);

    function onRating(label_id: number, label: string, bookMatch: BookMatch,
                      oldRating: number | null, newRating: number | null) {
        if (!newRating) return;
        let o: {[key: number]: MatchEvaluation} = {};
        o[label_id] = {
            rating: newRating,
            label: label,
            textTitle: bookMatch.title,
            textAuthor: bookMatch.author
        };
        setLabelEvals({...labelEvals, ...o})
        labelEvalRef.update(o)
            .then(() => {
                setSuccessOpen(true);
                if (!oldRating && newRating && myUserId) {
                    userEvalCountRef.transaction(function(currentCount) {
                        return (currentCount || 0) + 1;
                    });
                }
            })
            .catch(reason => {
                setErrorOpen(true);
            });
    }

    return (
        <div>
            <Snackbar open={successOpen} autoHideDuration={1000} onClose={handleSuccessClose}>
                <Alert onClose={handleSuccessClose} severity="success">
                    Evaluation saved, great!
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} autoHideDuration={3000} onClose={handleErrorClose}>
                <Alert onClose={handleErrorClose} severity="error">
                    Could not save evaluation.
                </Alert>
            </Snackbar>

            <TableContainer component={Paper}>
                <Table size="small" aria-label="label top-%">
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell align="right">
                                Quantile
                            </TableCell>
                            <TableCell align="right">
                                <div className={'flex flex-row'}>
                                <div className={'text-center'}>Feedback</div>
                                {!myUserId &&
                                <Tooltip placement={'left'}
                                    title={'As an anonymous user you are welcome to submit feedback, but it won\'t be associated with your account'}>
                                  <WarningIcon fontSize={'small'} color={'primary'} className={'ml-2'}/>
                                </Tooltip>
                                }
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.bookMatch.matches && props.bookMatch.matches.map((match, idx) => {
                            const label = props.labels && props.labels[idx] && props.labels[idx].labelMatch.label;
                            const labelId = props.labels && props.labels[idx] && props.labels[idx].labelMatch.label_id;
                            const value = labelEvals ? labelEvals[labelId] : undefined;
                            const prevRating = value && value.rating || 0;
                            const className = !value ? 'invisible group-hover:visible' : '';
                            return (
                                <TableRow key={label} className={'group'}>
                                    <TableCell component="th" scope="row">
                                        {label}
                                    </TableCell>
                                    <TableCell align="right">
                                        {(extendedPercentileToPercents(match)) + '%'}
                                    </TableCell>
                                    <TableCell align="right">
                                        <div className={className}>
                                            <Rating
                                                name={label}
                                                value={prevRating}
                                                max={3}
                                                size={'small'}
                                                getLabelText={(value: number) => customIcons[value].label}
                                                IconContainerComponent={IconContainer}
                                                onChange={(event, newValue) => {
                                                    onRating(labelId, label, props.bookMatch, prevRating, newValue);
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}