import {useEffect} from "react";

const Iubenda = (props: { policyId: string }) => {
    useEffect(() => {
        var s = document.createElement("script");
        let tag = document.getElementsByTagName("script")[0];

        s.src = "https://cdn.iubenda.com/iubenda.js";

        tag && tag.parentNode && tag.parentNode.insertBefore(s, tag);
    }, []);

    return <a href={`https://www.iubenda.com/privacy-policy/${props.policyId}`}
              className="iubenda-white iubenda-embed"
              title="Privacy Policy ">Privacy Policy</a>
};

function Privacy() {
    const policyId = '8227276';

    return (
        <div className={'mx-auto mt-20'}>
            <Iubenda policyId={policyId}/>
        </div>
    )
}

export default Privacy;