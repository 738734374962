import fb from './firebase'
import {useObject} from 'react-firebase-hooks/database';
import React, {useEffect, useState} from "react";
import DeleteAccountModal from "./DeleteAccountModal";
import {useHistory} from "react-router-dom";
import {useDebounce, useLocalStorage} from "./utils";
import numeral from "numeral";

const downloadFile = async (data: any) => {
    const fileName = "text_study_data_takeout_" + new Date().toISOString();
    const json = JSON.stringify(data);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const AccountPage = () => {
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    const [isInitialLogin, setIsInitialLogin] = useState(false);

    let currentUser = fb.auth().currentUser;
    let myUserId = currentUser && currentUser.uid;

    const onDataDownload = () => {
        const labels = fb.database().ref('user-labels/' + myUserId + '/list/')
        const evaluations = fb.database().ref('user-text-label-eval/' + myUserId + '/')
        labels.get().then(labelSnapshot => {
            evaluations.get().then(evalSnapshot => {
                const labels = labelSnapshot.exportVal();
                downloadFile({
                    labels: labels && Object.keys(labels).map(labelId => labels[labelId].label),
                    evaluations: evalSnapshot.exportVal()
                });
            });
        });
    }

    useEffect(() => {
        if (currentUser && !currentUser.isAnonymous) {
            if (currentUser.metadata.creationTime && Date.now() - Date.parse(currentUser.metadata.creationTime) < 10000) {
                setIsInitialLogin(true);
            } else {
                setIsInitialLogin(false);
            }
        }
    }, [currentUser]);

    const notificationsJobsRef = fb.database().ref('user-settings/' + myUserId + '/notifications/jobs');
    const notificationsNewsRef = fb.database().ref('user-settings/' + myUserId + '/notifications/news');
    const notificationsFeedbackRef = fb.database().ref('user-settings/' + myUserId + '/notifications/feedback');

    useEffect(() => {
        if (!myUserId) return;
        notificationsJobsRef.get().then(snapshot => setJobNotifications(snapshot.val() || false)).catch(reason => console.log(reason));
        notificationsNewsRef.get().then(snapshot => setNewsNotifications(snapshot.val() || false)).catch(reason => console.log(reason));
        notificationsFeedbackRef.get().then(snapshot => setFeedbackNotifications(snapshot.val() || false)).catch(reason => console.log(reason));
    }, [myUserId]);

    let gpt3QuotaRef = fb.database().ref('user-settings/' + myUserId + '/quota/gpt3');
    const [gpt3Quota, loadingGpt3Quota, errorGpt3Quota] = useObject(gpt3QuotaRef);
    const gpt3QuotaValue = gpt3Quota?.val();

    let activeLabelsQuotaRef = fb.database().ref('user-settings/' + myUserId + '/quota/activeLabels');
    const [activeLabelsQuota, loadingActiveLabelsQuota, errorActiveLabelsQuota] = useObject(activeLabelsQuotaRef);
    const activeLabelsQuotaValue = activeLabelsQuota?.val();

    const [interests, setInterests] = useState<string>('');
    const [interestsLoaded, setInterestsLoaded] = useState<boolean>(false);
    const debouncedInterests = useDebounce(interests, 2000);

    let userEvalCountRef = fb.database().ref('user-settings/' + myUserId + '/stats/match_evaluations');
    const [userEvalVal, loadUserEvalVal, errorUserEvalVal] = useObject(userEvalCountRef);
    const evaluationCount = userEvalVal?.val();

    let labelRef = fb.database().ref('user-labels/' + myUserId + '/count');
    const [userLabelCountVal, loadUserLabelCountVal, errorUserLabelCountVal] = useObject(labelRef);
    const labelCount = userLabelCountVal?.val();


    useEffect(() => {
        if (!myUserId) return;
        let interestsRef = fb.database().ref('user-settings/' + myUserId + '/interests/value');
        interestsRef.get().then(snapshot => {
            setInterests(snapshot.val() || '');
            setInterestsLoaded(true);
        });
    },[myUserId]);
    useEffect(() => {
        if (!myUserId || !interestsLoaded) return;
        let interestsRef = fb.database().ref('user-settings/' + myUserId + '/interests/value');
        interestsRef.set(debouncedInterests);
    }, [interests, debouncedInterests, myUserId]);


    const [jobNotifications, setJobNotifications] = useState<boolean>(false);
    const [newsNotifications, setNewsNotifications] = useState<boolean>(false);
    const [feedbackNotifications, setFeedbackNotifications] = useState<boolean>(false);

    let history = useHistory();
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false)

    useEffect(() => {
        if (!isSignedIn && !currentUser) {
            history.push('/signin')
        }
    }, [isSignedIn, currentUser, history]);

    if (!myUserId) {
        return null;
    }

    const onSubmit = function(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    return (
        <div className={'flex flex-col bg-gray-100 md:shadow-xl p-4 mt-2 md:pt-6 md:mt-4'}>
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm text-gray-600">This information is private.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="first_name"
                                               className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <div
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                            {currentUser?.displayName}
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="email_address"
                                               className="block text-sm font-medium text-gray-700">
                                            Email address
                                        </label>
                                        <div
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                            {currentUser?.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Research interests</h3>
                            <p className="mt-1 text-sm text-gray-600">This information is private.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-2">
                                    <div className="col-span-2 sm:col-span-3">
                                        <label htmlFor="first_name"
                                               className="block text-sm font-medium text-gray-700">
                                            Areas of interest
                                        </label>
                                        <div>
                                            <textarea className="w-full px-3 py-2 mt-4 text-gray-700 border rounded-lg focus:outline-none"
                                                      rows={3}
                                                      value={interests}
                                                      placeholder={"E.g: French literature of the 18th and 19th century, French romanticism, history of poetry, Charles Baudelaire, orientalism in literature, diaspora studies"}
                                                      onChange={(event)=> setInterests(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Service plan</h3>
                            <p className="mt-1 text-sm text-gray-600">Available features and quota.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-6">
                                        <label htmlFor="first_name"
                                               className="block text-sm text-2xl text-gray-700">
                                            Basic
                                        </label>
                                    </div>

                                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                        <label htmlFor="email_address"
                                               className="block text-sm font-medium text-gray-700">
                                            Available GPT-3 quota
                                        </label>
                                        <div
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                            {numeral(gpt3QuotaValue).format('0a')} tokens
                                        </div>
                                    </div>
                                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                        <label htmlFor="email_address"
                                               className="block text-sm font-medium text-gray-700">
                                            Accelerated labels
                                        </label>
                                        <div
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                            {numeral(activeLabelsQuotaValue).format('0a')} labels
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Data</h3>
                            <p className="mt-1 text-sm text-gray-600">The data you contributed to the system.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">

                                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Labels
                                        </label>
                                        <div
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                            {labelCount > 0 ? numeral(labelCount).format('0a') : '-'}
                                        </div>
                                    </div>
                                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Match quality evaluations
                                        </label>
                                        <div className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                            {evaluationCount > 0 ? numeral(evaluationCount).format('0a') : '-'}
                                        </div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-6">
                                        <div className="block font-medium text-gray-700 underline cursor-pointer"
                                        onClick={labelCount + evaluationCount > 0 ? onDataDownload : () => {}}>
                                            Takeout
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
                            <p className="mt-1 text-sm text-gray-600">Decide which communications you'd like to
                                receive.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form onSubmit={onSubmit}>
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                    <fieldset>
                                        <legend className="text-base font-medium text-gray-900">By Email</legend>
                                        <div className="mt-4 space-y-4">
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="comments"
                                                        name="comments"
                                                        type="checkbox"
                                                        checked={jobNotifications}
                                                        onChange={() => {
                                                            notificationsJobsRef.set(!jobNotifications);
                                                            setJobNotifications(!jobNotifications);
                                                        }}
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="comments" className="font-medium text-gray-700">
                                                        Long-running jobs
                                                    </label>
                                                    <p className="text-gray-500">Get notified when the long-running
                                                        analyses
                                                        of texts according to your labels have been completed.</p>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="candidates"
                                                        name="candidates"
                                                        type="checkbox"
                                                        checked={newsNotifications}
                                                        onChange={() => {
                                                            notificationsNewsRef.set(!newsNotifications);
                                                            setNewsNotifications(!newsNotifications);
                                                        }}
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="candidates"
                                                           className="font-medium text-gray-700">
                                                        News
                                                    </label>
                                                    <p className="text-gray-500">Get notified when new features
                                                        become available.</p>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="offers"
                                                        name="offers"
                                                        type="checkbox"
                                                        checked={feedbackNotifications}
                                                        onChange={() => {
                                                            notificationsFeedbackRef.set(!feedbackNotifications);
                                                            setFeedbackNotifications(!feedbackNotifications);
                                                        }}
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="offers" className="font-medium text-gray-700">
                                                        Feedback
                                                    </label>
                                                    <p className="text-gray-500">Whether we may ask you about how to
                                                        make <span className={'font-medium ml-0.5 mx-1'}>text.study</span>
                                                        more useful for you.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            {!isInitialLogin && <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Danger Zone</h3>
                            <p className="mt-1 text-sm text-gray-600"></p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2 md:flex md:flex-row-reverse sm:pr-6">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => setOpenDeleteAccount(true)}
                        >
                            Delete account
                        </button>
                        <DeleteAccountModal open={openDeleteAccount} setOpen={setOpenDeleteAccount}/>
                    </div>
                </div>
            </div>}

            {isInitialLogin && <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2 md:flex md:flex-row sm:pr-6">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                      onClick={() => history.push('/')}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>}

        </div>
    );
}