import firebase from 'firebase';

// Configure Firebase.
const prodConfig = {
    apiKey: 'AIzaSyB_YS2C8LGzJGNikDkR06zaxEez24r_nCo',
    authDomain: 'text.study',
    databaseURL: "https://text-study-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "text-study",
    storageBucket: "text-study.appspot.com",
    messagingSenderId: "784694361450",
    appId: "1:784694361450:web:d0db8a4a897d8ee1de7913"
};
const devConfig = {
    apiKey: 'AIzaSyB_YS2C8LGzJGNikDkR06zaxEez24r_nCo',
    authDomain: 'localhost',
    // databaseURL: "https://text-study-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "text-study",
    storageBucket: "text-study.appspot.com",
    messagingSenderId: "784694361450",
    appId: "1:784694361450:web:d0db8a4a897d8ee1de7913"
};
const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

firebase.initializeApp(config);

if (process.env.NODE_ENV !== 'production') {
    firebase.auth().useEmulator("http://localhost:9099");
    firebase.functions().useEmulator("localhost", 5001);
    firebase.database().useEmulator("localhost", 9000);
}

// Configure FirebaseUI.
export const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            clientId: '784694361450-c7ske9g0e30sobkusqmm5fmc5gbr0p2i.apps.googleusercontent.com'
        },
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

export default firebase