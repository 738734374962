import {Link} from "react-router-dom";
import Wrapper from "./lib/react-wiki-preview/Wrapper";
import {BookMatch} from "./types/Analysis";
import {wikiLogo} from "./lib/react-wiki-preview/Content";
import {Popover, Tooltip} from "@material-ui/core";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {useRef, useState} from "react";
import {ItemType} from "./SearchPage";
import {decodeHtml} from "./utils";
import {MatchLabelQuantiles} from "./MatchLabelQuantiles";


function percentageToHsl(percentage: number, hue0: number, hue1: number): string {
    let hue = (percentage * (hue1 - hue0)) + hue0;
    return 'hsl(' + hue + ', 100%, 50%)';
}

function BookMatchItem(props: {
    bookMatch: BookMatch,
    labels?: ItemType[],
    onExplain?: (elt: HTMLDivElement) => void,
    disableTooltips?: boolean,
    anonHash?: string
}) {

    const [openedPopover, setOpenedPopover] = useState(false);
    const popoverAnchor = useRef(null);

    const popoverEnter = function(currentTarget: any) {setOpenedPopover(true)};
    const popoverLeave = function(currentTarget: any) {setOpenedPopover(false)};

    return (
        <div key={props.bookMatch.book_id} className={"group flex my-4 mr-2 justify-between items-center bg-gradient-to-r hover:from-gray-100"}>
            <div className={"inline-block lg:flex lg:flex-row min-w-1/2 border-l-2 pl-2"}
                 style={{borderColor: percentageToHsl(props.bookMatch.score, 0, 120)}}>
                <Link to={`/t/${encodeURI(props.bookMatch.title + (props.bookMatch.author ? ", " + props.bookMatch.author : ""))}`}
                      className={"text-black sm:whitespace-nowrap"}>
                    {decodeHtml(props.bookMatch.title)}
                </Link>
                <div className={'sm:whitespace-nowrap flex flex-row'}>
                    {props.bookMatch.author && (
                        <div className={'flex flex-row'}>
                            <br className={'sm:hidden'}/>
                            <span className={'hidden lg:block'}>, by</span>
                            <Link to={'/author/' + props.bookMatch.author}
                                  className={'text-gray-400 font-bold lg:ml-1 border-0'}>{props.bookMatch.author}</Link>
                        </div>
                    )}
                    {props.bookMatch.pubyear &&
                    <span className={"text-sm leading-6 pl-1"}>({props.bookMatch.pubyear})</span>}
                </div>
            </div>
            <div className={"flex flex-col sm:flex-row items-center justify-between sm:ml-4"}>
                {(props.bookMatch.matches && props.bookMatch.matches.length > 0 &&
                    props.labels && props.labels.length === props.bookMatch.matches.length) &&
                <div className={'flex flex-row items-center'}>
                  <div className={'invisible group-hover:visible text-gray-500'}
                       ref={popoverAnchor}
                       aria-owns={"mouse-over-popover-" + props.bookMatch.book_id}
                       aria-haspopup="true"
                       onMouseEnter={popoverEnter}
                       onMouseLeave={popoverLeave}
                  >
                    <EqualizerIcon/>

                    <Popover
                        id={"mouse-over-popover-" + props.bookMatch.book_id}
                        className={'pointer-events-none'}
                        classes={{
                            paper: 'pointer-events-auto'
                        }}
                        marginThreshold={21}
                        open={openedPopover}
                        anchorEl={popoverAnchor.current}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        disableRestoreFocus={true}
                        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
                    >

                      <MatchLabelQuantiles bookMatch={props.bookMatch} labels={props.labels} anonHash={props.anonHash}/>

                    </Popover>
                  </div>
                </div>
                }

                {props.bookMatch.wikipedia_title &&
                <Tooltip title="Open Wikipedia article in a new page" placement="bottom-start"
                         arrow disableTouchListener={props.disableTooltips}>
                  <div className={'invisible group-hover:visible ml-2 sm:ml-4 mt-2 sm:mt-0'}>
                    <Wrapper keyword={props.bookMatch.wikipedia_title}>
                        {wikiLogo('light')}
                    </Wrapper>
                  </div>
                </Tooltip>
                }
                {props.onExplain &&
                <div className={'invisible group-hover:visible ml-4 underline cursor-pointer'}
                     onClick={(element) => props.onExplain && props.onExplain(element.currentTarget)}>
                  <Tooltip title="Explain how this text corresponds to the given plot element" placement="bottom" arrow
                           disableTouchListener={props.disableTooltips}>
                    <div>[explain]</div>
                  </Tooltip>
                </div>
                }
            </div>
        </div>
    )
}

export default BookMatchItem;