import React, {Fragment, CSSProperties} from 'react'
import Tooltip from './Tooltip'

type Props = {
    children: JSX.Element
    keyword: string
    theme: 'light' | 'dark'
    anchorStyles: CSSProperties
    mouseEnterDelay: number
}

type States = {
    str: (string | JSX.Element)
}

export default class Wrapper extends React.Component<Props, States> {
    static defaultProps = {
        theme: 'light',
        anchorStyles: {'cursor': 'pointer', zIndex: 10},
        mouseEnterDelay: 0
    }

    state: States = {
        str: ''
    }

    componentDidMount() {
        this.addAnchor(this.props.children)
    }

    addAnchor = (child: JSX.Element) => {
        const { keyword, theme, anchorStyles, mouseEnterDelay } = this.props
        const value = keyword;

        let replacement = (
            <Fragment>
                {` `}
                <Tooltip
                    theme={theme}
                    value={value}
                    mouseEnterDelay={mouseEnterDelay}
                >
                    <a
                        style={anchorStyles}
                        target={'_blank'}
                        href={`https://en.wikipedia.org/wiki/${value}`}
                    >
                        {child}
                    </a>
                </Tooltip>
            </Fragment>
        )

        this.setState({
            str: replacement
        })
    }

    render() {
        return <div>{this.state.str}</div>
    }
}