import fb from "../firebase"

import {useEffect, useRef, useState} from 'react';
import {Service} from '../types/Service';
import {LabelSearch} from '../types/Analysis';
import {md5} from "../utils";

const findSimilarLabels = process.env.NODE_ENV === 'production' ?
    fb.app().functions('europe-west1').httpsCallable('findSimilarLabels') :
    fb.app().functions().httpsCallable('findSimilarLabels');

const usePostSearchLabelsService = (query: string) => {
    const request = {query};
    const [result, setResult] = useState<Service<LabelSearch>>({
        status: 'loading', request
    });
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        mountedRef.current = true;
        if (query.length === 0) {
            return;
        }

        if (result.status === 'loaded') {
            setResult({status: 'updating', prevPayload: result.payload, request})
        } else {
            setResult({status: 'loading', request})
        }

        let queryRef = fb.database().ref('label-search-results/' + md5(query, 16));

        let onDataChange = queryRef.on('value', (snapshot: fb.database.DataSnapshot) => {
            const val = snapshot.val();
            if (val) {
                setResult({status: 'loaded', payload: val, request});
            }
        });

        const data = {query: query};

        findSimilarLabels(data)
            .then((result) => {
                mountedRef.current && setResult({status: 'loaded', payload: result.data, request})
            })
            .catch((error) => {
                console.error(error);
                setResult({status: 'error', error, request})
            });
        return () => {
            mountedRef.current = false;
            queryRef.off('value', onDataChange);
        };
    }, [query]);

    return result;
};

export default usePostSearchLabelsService;