import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Label} from "./types/Analysis";
import numeral from "numeral";
import HistoricalUse from "./HistoricalUse";
import {Link} from "react-router-dom";
import LabelPercentiles from "./LabelPercentiles";
import {TableFooter, TablePagination} from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import fb from "./firebase";
import Hashes from "jshashes";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props: { row: Label }) {
    const {row} = props;
    const [open, setOpen] = useState(false);
    const [done, setDone] = useState(0)
    const [totalWordCount, setTotalWordCount] = useState(0)
    const classes = useRowStyles();

    const md5Hash = new Hashes.MD5().hex(row.label).substr(0, 12);

    let refDone = fb.database().ref('label-stats/' + md5Hash + '/done');
    let refTotalWordCount = fb.database().ref('label-stats/' + md5Hash + '/total_word_count');

    useEffect(() => {
        const handlerDone = refDone.on('value', (snapshot: fb.database.DataSnapshot) => {
            setDone(snapshot.val());
        });
        const handlerTWC = refTotalWordCount.on('value', (snapshot: fb.database.DataSnapshot) => {
            setTotalWordCount(snapshot.val());
        });
        return () => {
            refDone.off('value', handlerDone);
            refTotalWordCount.off('value', handlerTWC);
        }
    }, [row.label])

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell component="th" scope="row">
                    <div className={"flex flex-row place-items-center"}
                         onClick={() => setOpen(!open)}>
                        <div className={"w-7 mr-2 sm:mr-4"}>
                            <IconButton aria-label="expand row" size="small">
                                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </div>
                        <Link to={`/l/${encodeURI(row.label)}`} className={"w-auto"}>
                            <span className={"hover:underline font-medium"}>{row.label}</span>
                        </Link>
                    </div>
                </TableCell>

                <TableCell align="right" padding={'none'} onClick={() => setOpen(!open)}>
                    <div className={'flex flex-col text-sm md:hidden pr-4 text-center'}>
                        <div>
                            {done
                                ? (Math.round((done || 0) * 10000) / 100) + ' %'
                                : '-'
                            }
                        </div>
                        <div>
                            {totalWordCount
                                ? totalWordCount < 1000
                                    ? numeral(totalWordCount).format('0 a')
                                    : numeral(totalWordCount).format('0.0 a')
                                : '-'}
                        </div>
                    </div>
                </TableCell>
                <TableCell align="right" onClick={() => setOpen(!open)} padding={'none'}>
                    <div className={'hidden md:block px-2'}>
                        {done
                            ? (Math.round((done || 0) * 10000) / 100) + ' %'
                            : '-'
                        }
                    </div>
                </TableCell>
                <TableCell align="right" onClick={() => setOpen(!open)} padding={'none'}>
                    <div className={'hidden md:block px-2'}>
                        {totalWordCount
                            ? totalWordCount < 1000
                                ? numeral(totalWordCount).format('0a')
                                : numeral(totalWordCount).format('0.0a')
                            : '-'}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open &&
                        <div className={"sm:flex sm:flex-row"}>
                          <div className={"sm:w-4/5"}>
                            <div>
                              Historical use
                            </div>
                            <HistoricalUse label={row.label}/>
                          </div>
                          <div className={"sm:w-1/5"}>
                            <div>
                              Quantiles
                            </div>
                            <LabelPercentiles label={row.label} size={150}/>
                          </div>
                        </div>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        label: PropTypes.string.isRequired,
        done: PropTypes.number.isRequired,
        total_word_count: PropTypes.number.isRequired,
        remaining_time: PropTypes.number.isRequired,
    }).isRequired,
};


export default function LabelTable(props: { rows: Label[] }) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <div className={"ml-9 sm:ml-11"}>Label</div>
                        </TableCell>
                        <TableCell align="right" padding={'none'}>
                            <div className={'md:hidden pr-4 text-center'}>
                                Texts/words<br/>processed
                            </div>
                        </TableCell>
                        <TableCell align="right" padding={'none'}>
                            <div className={'hidden md:block px-2'}>Texts processed</div>
                        </TableCell>
                        <TableCell align="right" padding={'none'}>
                            <div className={'hidden md:block px-2'}>Words processed</div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                            ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : props.rows
                    ).map((row) => (
                        <Row key={row.label} row={row}/>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, {label: 'All', value: -1}]}
                            colSpan={4}
                            count={props.rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}